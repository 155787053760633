import React from 'react';

// Función para capitalizar la primera letra de un string
const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

// Mapeo para descripciones legibles de los filtros
const filterDisplayMap = {
    car_seats: (value) => `${value} seats`,
    car_doors: (value) => `${value} doors`,
    car_max_price: (value) => `to ${value} €`,
    car_min_price: (value) => `from ${value} €`,
    car_max_km: (value) => `to ${value} km`,
    car_min_km: (value) => `from ${value} km`,
    car_max_year: (value) => `to ${value}`,
    car_min_year: (value) => `from ${value}`,
};

const Filters_header = ({ filters, onRemoveFilter, onRemoveAllFilters }) => {
    // Filtrar las entradas para excluir 'car_location_country', 'car_location_region', 'car_version' y '__temp__force_update'
    const filteredEntries = Object.entries(filters).filter(
        ([key]) =>
            key !== 'car_location_country' &&
            key !== 'car_location_region' &&
            key !== 'car_version' &&
            key !== '__temp__force_update' &&
            filters[key] !== undefined // Aseguramos que no hay valores undefined
    );

    if (filteredEntries.length === 0) {
        return null; // No se muestra nada si no hay filtros seleccionados
    }

    // Calcular el número total de opciones seleccionadas
    const totalSelectedFilters = filteredEntries.reduce((total, [key, value]) => {
        return total + (Array.isArray(value) ? value.length : 1);
    }, 0);

    // Función para obtener la descripción legible del filtro
    const getFilterLabel = (key, value) => {
        if (value === undefined || value === null) {
            return ''; // Retorna vacío si el valor es undefined o null
        }

        if (filterDisplayMap[key]) {
            return filterDisplayMap[key](value);
        }

        return capitalize(value.toString ? value.toString() : String(value));
    };

    return (
        <div className="filters-header">
            {filteredEntries.map(([key, value]) =>
                Array.isArray(value) ? (
                    // Si el valor es un array, muestra un botón por cada opción
                    value.map((option, index) => (
                        option !== undefined && ( // Validar valores undefined dentro de arrays
                            <div key={`${key}-${index}`} className="filter-item">
                                {getFilterLabel(key, option)}
                                <button
                                    className="remove-filter"
                                    onClick={() => onRemoveFilter(key, option)} // Pasamos también la opción
                                >
                                    &times;
                                </button>
                            </div>
                        )
                    ))
                ) : (
                    value !== undefined && ( // Validar valores undefined para filtros únicos
                        <div key={key} className="filter-item">
                            {getFilterLabel(key, value)}
                            <button
                                className="remove-filter"
                                onClick={() => onRemoveFilter(key)}
                            >
                                &times;
                            </button>
                        </div>
                    )
                )
            )}
            {totalSelectedFilters > 1 && ( // Mostrar si hay más de un filtro seleccionado
                <div className="filter-item-remove-all">
                    <button className="remove-all-filters" onClick={onRemoveAllFilters}>
                        borrar filtros
                    </button>
                </div>
            )}
        </div>
    );
};


export default Filters_header;
