import React from 'react';
import logo from './logotest.png';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const currentYear = new Date().getFullYear();
    const { t } = useTranslation();

    return (
        <footer className="footer">
            <div className="footer-columns">
                
                <div className="footer-column">
                    <img src={logo} alt="Logo" className="footer-logo" />
                </div>
                
                <div className="footer-column">
                    <h4 className="footer-title">{t('links')}</h4>
                    <ul className="footer-links">
                        {/*<li><Link to="/results" className="footer-link">{t('sellCar')}</Link></li> */}
                        {/*<li><Link to="/" className="footer-link">{t('carHistory')}</Link></li> */}
                        {/*<li><Link to="/" className="footer-link">{t('insurance')}</Link></li> */}
                        <li><a href="/" className="footer-link">{t('news')}</a></li>
                    </ul>
                </div>

                <div className="footer-column">
                    <h4 className="footer-title">{t('legal')}</h4>
                    <ul className="footer-links">
                        <li><Link to="/results" className="footer-link">{t('cookies')}</Link></li>
                        <li><Link to="/" className="footer-link">{t('privacyPolicy')}</Link></li>
                        <li><Link to="/" className="footer-link">{t('termsConditions')}</Link></li>
                    </ul>
                </div>
                
                <div className="footer-column">
                    <h4 className="footer-title">{t('contact')}</h4>
                    <p className="footer-contact">hello@spotmyauto.com</p>
                </div>
            </div>
            <div className="footer-copyright">
                &copy; {currentYear} SpotMyAuto. {t('allRightsReserved')}
            </div>
        </footer>
    );
};

export default Footer;
