import React from 'react';
import logo from './nophoto.png';
import yearIcon from './icons/calendar-check-svgrepo-com copy.svg';
import mileageIcon from './icons/chart-bar-vertical-01-svgrepo-com copy.svg';
import gearboxIcon from './icons/settings-svgrepo-com copy.svg';
import fuelIcon from './icons/water-drop-svgrepo-com copy.svg';
import electricIcon from './icons/thunder-svgrepo-com.svg';
import automaticIcon from './icons/manual_icon.svg';

import spainFlag from './icons/icon_flags/flag-es-svgrepo-com.svg';
import germanyFlag from './icons/icon_flags/flag-es-svgrepo-com.svg';
import franceFlag from './icons/icon_flags/flag-es-svgrepo-com.svg';

const Car_card = (props) => {
  const car = props.car  ||  {
    car_img: logo,
    car_location_region: "Madrid",
    car_location_country: "Spain",
    car_brand: "Tesla",
    car_model: "Model 3",
    car_version: "Long Range",
    car_price: 50000,
    car_year: 2021,
    car_mileage: 20000,
    car_gearbox: "Automatic",
    car_fuel: "Electric",
  };

  const formatPrice = (price) => {
    return price.toLocaleString('de-DE'); // Formato europeo (1.234.567,89)
  };

  const formatMileage = (mileage) => {
    return mileage.toLocaleString('de-DE'); // Separador de miles
  };

  const getFuelIcon = (fuelType) => {
    return fuelType?.toLowerCase() === 'electric' ? electricIcon : fuelIcon;
  };

  const getGearboxIcon = (gearboxType) => {
    return gearboxType?.toLowerCase() === '' ? automaticIcon : gearboxIcon;
  };

  const countryFlags = {
    Spain: spainFlag,
    Germany: germanyFlag,
    France: franceFlag,
    // Agrega más países y banderas aquí
  };

  return (
    <div className="car_card">
      <div className="car-img">
        <img 
          src={car.car_img || logo} 
          alt={`${car.car_brand} ${car.car_model}`} 
          className="car_image"
          onError={(e) => { e.target.src = logo; }} 
        />
      </div>

      <div className="car-info">
        <div className="car_location">
          <span>
            {car.car_location_region && `${car.car_location_region}, `}
            {car.car_location_country}
          </span>
          {countryFlags[car.car_location_country] && (
            <img 
              src={countryFlags[car.car_location_country]} 
              alt={car.car_location_country} 
              className="car_card_country_flag" 
            />
          )}
        </div>

        <span className="car_brand_model">{car.car_brand} {car.car_model}</span>
        <span className="car_version">{car.car_version || ""}</span>
        <span className="car_price">{formatPrice(car.car_price)} €</span>
        <div className="car_year_mileage_gearbox_fuel">
          {car.car_year && (
            <div className="car_card_detail">
              <img src={yearIcon} alt="Year" className="car_card_icon" />
              <span>{car.car_year}</span>
            </div>
          )}
          {car.car_mileage && (
            <div className="car_card_detail">
              <img src={mileageIcon} alt="Mileage" className="car_card_icon" />
              <span>{formatMileage(car.car_mileage)} km</span>
            </div>
          )}
          {car.car_gearbox && (
            <div className="car_card_detail">
              <img src={getGearboxIcon(car.car_gearbox)} alt="Gearbox" className="car_card_icon" />
              <span>{car.car_gearbox}</span>
            </div>
          )}
          {car.car_fuel && (
            <div className="car_card_detail">
              <img src={getFuelIcon(car.car_fuel)} alt="Fuel" className="car_card_icon" />
              <span>{car.car_fuel}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Car_card;
