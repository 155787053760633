import React from 'react';
import iphone_photo from './iphone_photo.png';

const WhyUs_section = () => {
  return (
    <section className="why-us-section">
      <h2 className="why-us-title">¿Por qué SpotMyAuto?</h2>
      <div className="why-us-container">
        {/* Recuadro izquierdo */}
        <div className="why-us-box left-box">
          <h3>Coches de todo Europa, en un solo lugar</h3>
          <p>Accede a un listado de coches de segunda mano de diferentes países europeos, todo desde una única plataforma.</p>
          <img src={iphone_photo} alt="Imagen ilustrativa" className="left-box-image" />
        </div>
        {/* Recuadros derechos */}
        <div className="right-box-container">
          <div className="why-us-box">
            <h3>Bucador con inteligencia artificial</h3>
            <p>Busca coches de una forma más fácil e intuitiva con nuestro buscador con inteligencia artificial.</p>
            <p className="ai-example">“Busco coches baratos de color rojo en Madrid”</p>
          </div>
          <div className="why-us-box">
            <h3>Encuentra el mejor precio</h3>
            <p>Compara precios, características y condiciones de vehículos de diferentes mercados europeos, para tomar decisiones más informadas y encontrar la mejor oferta.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs_section;
