import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SearchBar from "./SearchBar";
import Categories_menu from "./Categories_menu";
import Brands_menu from "./Brands_menu";
import TypeCar_menu from "./TypeCar_menu";
import AdvancedFilters from "./AdvancedFilters";
import ToggleSwitch from "./ToggleSwitch";
import QuickFilters from "./QuickFilters";
import WhyUs_section from "./WhyUs_section";
import ScrollingText from "./ScrollingText";
import ContactUsSection from "./ContactUs_section";
import { Link } from 'react-router-dom';
import QuickLinks from "./QuickLinks";

function Home() {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const [query, setQuery] = useState(null);
    const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
    const [useAISearch, setUseAISearch] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const navigate = useNavigate();
    const searchInputRef = useRef(null);

    const routeTranslations = {
        usedCars: {
          es: 'coches-segunda-mano',
          en: 'used-cars',
          fr: 'voitures-occasion',
          de: 'gebrauchtwagen',
        },
      };

      const getTranslatedRoute = (key, lang) => {
        return routeTranslations[key][lang] || routeTranslations[key].en; // Por defecto usa inglés
      };

    useEffect(() => {
        // Detectar si es móvil
        const updateIsMobile = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        updateIsMobile();
        window.addEventListener('resize', updateIsMobile);
        
        return () => {
            window.removeEventListener('resize', updateIsMobile);
        };
    }, []);

    useEffect(() => {
        // Sólo hacer focus si el ancho de pantalla es mayor a 768px (escritorio o tablet en modo horizontal)
        if (searchInputRef.current && window.innerWidth > 768) {
            searchInputRef.current.focus();
        }
    }, []);

    const handleSearch = (query, filters = {}) => {
        if (!query?.trim() && !Object.keys(filters).length) {
            console.log("Search query and filters are empty. No search performed.");
            return;
        }
        setQuery(query);
    
        const currentLang = i18n.language; // Obtén el idioma actual
        const translatedRoute = getTranslatedRoute('usedCars', currentLang); // Traduce 'used-cars' al idioma actual
    
        let url = `/${currentLang}/${translatedRoute}?query=${encodeURIComponent(query || '')}`;
        if (Object.keys(filters).length > 0) {
            const filtersString = encodeURIComponent(JSON.stringify(filters));
            url += `&filters=${filtersString}`;
        }
        navigate(url);
        window.scrollTo({ top: 0 });
    };
    

    const handleClick = () => {
        const currentLang = i18n.language;
        const translatedRoute = getTranslatedRoute('usedCars', currentLang); // Traduce 'used-cars' al idioma actual
        navigate(`/${currentLang}/${translatedRoute}`);
        window.scrollTo({ top: 0 });
    };
    

    const toggleAdvancedFilters = () => {
        setShowAdvancedFilters(!showAdvancedFilters);
    };

    const handleSwitchChange = () => {
        setUseAISearch(!useAISearch);
    };

    return (
        <div className="home">
            <h1 dangerouslySetInnerHTML={{ __html: t('findYourIdealCar') }}></h1>
            <p dangerouslySetInnerHTML={{ __html: isMobile ? t('analyzingSitesMobile') : t('analyzingSites') }}></p>
            {/*<ToggleSwitch checked={useAISearch} onChange={handleSwitchChange} />*/}
            <SearchBar onSearch={handleSearch} ref={searchInputRef}/>
            <QuickFilters onFilterSelect={handleSearch} />
            
            <button onClick={handleClick} className="ver-todo-button">{t('seeAll')}</button>
            <button onClick={toggleAdvancedFilters} className="ver-todo-button">{t('advanced')}</button>
            {showAdvancedFilters && <div className="overlay" onClick={toggleAdvancedFilters}></div>}
            {showAdvancedFilters && <AdvancedFilters onClose={toggleAdvancedFilters} onSearch={handleSearch} />}
            <WhyUs_section />
            <ScrollingText />
            
            <ContactUsSection />

            <QuickLinks />
            
        </div>
    );
}

export default Home;
