import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import arrow from './icons/caret-down-md-svgrepo-com.svg';

const QuickLinks = () => {
    const { i18n } = useTranslation(); // Accede al idioma actual
    const currentLang = i18n.language;

    const routeTranslations = {
        usedCars: {
          es: 'coches-segunda-mano',
          en: 'used-cars',
          fr: 'voitures-occasion',
          de: 'gebrauchtwagen',
        },
      };

      const getTranslatedRoute = (key, lang) => {
        return routeTranslations[key][lang] || routeTranslations[key].en; // Por defecto usa inglés
      };
    
    const brands = [
        'ABARTH', 'AIWAYS', 'ALFA ROMEO', 'ALPINE', 'ARO', 'ASIA', 'ASIA MOTORS',
        'ASTON MARTIN', 'AUDI', 'AUSTIN', 'BENTLEY', 'BERTONE', 'BMW', 'BYD',
        'CADILLAC', 'CHEVROLET', 'CHRYSLER', 'CITROEN', 'CORVETTE', 'CUPRA',
        'DACIA', 'DAEWOO', 'DAIHATSU', 'DAIMLER', 'DFSK', 'DODGE', 'DR AUTOMOBILES',
        'DS', 'EVO', 'FERRARI', 'FIAT', 'FORD', 'GALLOPER', 'HONDA', 'HUMMER',
        'HYUNDAI', 'INEOS', 'INFINITI', 'INNOCENTI', 'ISUZU', 'IVECO', 'IVECO-PEGASO',
        'JAECOO', 'JAGUAR', 'JEEP', 'KGM', 'KIA', 'KTM', 'LADA', 'LAMBORGHINI',
        'LANCIA', 'LAND-ROVER', 'LDV', 'LEAPMOTOR', 'LEVC', 'LEXUS', 'LIGIER',
        'LOTUS', 'LYNK & CO', 'MAHINDRA', 'MASERATI', 'MAXUS', 'MAYBACH', 'MAZDA',
        'MCLAREN', 'MERCEDES-BENZ', 'MG', 'MINI', 'MITSUBISHI', 'MORGAN', 'NISSAN',
        'OMODA', 'OPEL', 'PEUGEOT', 'PIAGGIO', 'POLESTAR', 'PONTIAC', 'PORSCHE',
        'RAM', 'RENAULT', 'RENAULT V.I.', 'ROLLS-ROYCE', 'ROVER', 'SAAB', 'SANTANA',
        'SEAT', 'SERES', 'SKODA', 'SKYWELL', 'SMART', 'SSANGYONG', 'SUBARU',
        'SUZUKI', 'SWM', 'TALBOT', 'TATA', 'TESLA', 'TOYOTA', 'UMM', 'VAZ',
        'VOLKSWAGEN', 'VOLVO', 'WARTBURG', 'YUDO', 'ZHIDOU'
    ];

    const regions = [
        'A Coruña', 'Álava', 'Albacete', 'Alicante', 'Almería', 'Asturias', 'Ávila',
        'Badajoz', 'Baleares', 'Barcelona', 'Burgos', 'Cáceres', 'Cádiz', 'Cantabria',
        'Castellón', 'Ceuta', 'Ciudad Real', 'Córdoba', 'Cuenca', 'Girona', 'Granada',
        'Guadalajara', 'Guipúzcoa', 'Huelva', 'Huesca', 'Jaén', 'La Rioja', 'Las Palmas',
        'León', 'Lleida', 'Lugo', 'Madrid', 'Málaga', 'Melilla', 'Murcia', 'Navarra',
        'Orense', 'Palencia', 'Pontevedra', 'Salamanca', 'Segovia', 'Sevilla', 'Soria',
        'Sta. C. Tenerife', 'Tarragona', 'Teruel', 'Toledo', 'Valencia', 'Valladolid',
        'Vizcaya', 'Zamora', 'Zaragoza'
    ];

    const [showBrands, setShowBrands] = useState(true);
    const [showRegions, setShowRegions] = useState(true);

    const toggleBrands = () => setShowBrands(!showBrands);
    const toggleRegions = () => setShowRegions(!showRegions);

    return (
        <div className="quick-links">
            {/* Sección de Marcas */}
            <div className="section-header" onClick={toggleBrands}>
                <h2>Coches de segunda mano por marca</h2>
                <img 
                    src={arrow} 
                    alt="toggle brands" 
                    className={`toggle-icon ${showBrands ? 'open' : 'closed'}`} 
                />
            </div>
            <div className={`brands-container ${!showBrands ? 'collapsed' : ''}`}>
                {brands.map((brand) => (
                    <Link 
                        to={`/${currentLang}/${brand.toLowerCase()}/${getTranslatedRoute('usedCars', currentLang)}`} 
                        key={brand}>
                        <button className="brand-button">{brand}</button>
                    </Link>
                
                ))}
            </div>

            {/* Sección de Regiones */}
            <div className="section-header" onClick={toggleRegions}>
                <h2>Coches de segunda mano por region</h2>
                <img 
                    src={arrow} 
                    alt="toggle regions" 
                    className={`toggle-icon ${showRegions ? 'open' : 'closed'}`} 
                />
            </div>
            <div className={`brands-container ${!showRegions ? 'collapsed' : ''}`}>
                {regions.map((region) => (
                    <Link 
                        to={`/${currentLang}/${getTranslatedRoute('usedCars', currentLang)}/${region.toLowerCase()}`} 
                        key={region}>
                        <button className="brand-button">{region}</button>
                    </Link>
                
                ))}
            </div>
        </div>
    );
};

export default QuickLinks;
