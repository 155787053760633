import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FilterIcon } from './icons/filter-svgrepo-com.svg';
import { ReactComponent as AIIcon } from './icons/stars-svgrepo-com.svg';
import { ReactComponent as OrderIcon } from './icons/caret-down-md-svgrepo-com.svg';

import OneSelect from './OneSelect2';

const Center_header_mobile = ({
    total,
    filters = {},
    onToggleFilters,
    onRemoveFilter,
    onRemoveAllFilters,
    order,
    onOrderChange,
    useAISearch,
    onToggleAISearch,
    onTransmissionFilterChange,
    isPending,
}) => {
    const { t } = useTranslation();
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [displayedTotal, setDisplayedTotal] = useState("0000");
    const [previousTotal, setPreviousTotal] = useState("0000");

    const formatNumberWithThousandsSeparators = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    useEffect(() => {
        if (isPending) {
            // Mientras los datos están pendientes, mostrar el número anterior
            setDisplayedTotal(previousTotal);
        } else {
            // Cuando los datos estén listos, actualizar el número mostrado y guardar el anterior
            const formattedTotal = formatNumberWithThousandsSeparators(total);
            setDisplayedTotal(formattedTotal);
            setPreviousTotal(formattedTotal);
        }
    }, [isPending, total, previousTotal]);

    const toggleDropdown = () => {
        setIsDropdownVisible(!isDropdownVisible);
    };

    const handleOrderChange = (value) => {
        onOrderChange(value); // Llama al método de orden
        setIsDropdownVisible(false); // Oculta el menú desplegable
    };

    return (
        <div className="center_header_mobile">
            {/* Contador de resultados y ordenación */}
            <div className="center_header_mobile-car_count">
                <span className="number">{displayedTotal}</span>{" "}
                <span className="text">{t('carsFound')}</span>

                {/* Botón y menú desplegable de ordenación */}
                <div className="center_header_mobile-order">
                    <button
                        className="center_header_mobile-order_button"
                        onClick={toggleDropdown}
                    >
                        {t('sort')} <OrderIcon className="center_header_mobile-order_icon" />
                    </button>
                    {isDropdownVisible && (
                        <ul className="center_header_mobile-dropdown_menu">
                            <li onClick={() => handleOrderChange('relevant')}>{t('relevant')}</li>
                            <li onClick={() => handleOrderChange('newest')}>{t('newest')}</li>
                            <li onClick={() => handleOrderChange('oldest')}>{t('oldest')}</li>
                            <li onClick={() => handleOrderChange('highest_price')}>{t('highestPrice')}</li>
                            <li onClick={() => handleOrderChange('lowest_price')}>{t('lowestPrice')}</li>
                        </ul>
                    )}
                </div>
            </div>
            
            {/*Filtros */}
            <div className="center_header_mobile-filters">

                {/* Botón de filtros */}
                <button
                    className="center_header_mobile-toggle_filters_btn"
                    onClick={() => {
                        onToggleFilters();
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}
                >
                    <FilterIcon className="center-header_filter-icon" />
                    {t('filter')}
                </button>

                {/* Filtros seleccionados */}
                <div className="center_header_mobile-filters-selected">
                    {Object.entries(filters).map(([key, value]) => (
                        <div key={key} className="center_header_mobile-filter-item">
                            {value.toString()}
                            <button className="center_header_mobile-remove-filter" onClick={() => onRemoveFilter(key)}>
                                &times;
                            </button>
                        </div>
                    ))}
                    {Object.entries(filters).length > 1 && (
                        <div className="center_header_mobile-filter-item-remove-all">
                            <button className="center_header_mobile-remove-all-filters" onClick={onRemoveAllFilters}>
                                {t('clearFilters')}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Center_header_mobile;
