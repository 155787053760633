import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import electricIcon from './icons/thunder-svgrepo-com.svg';
import hybridIcon from './icons/leaf-svgrepo-com-2.svg';
import priceIcon from './icons/tag-svgrepo-com copy.svg';
import seatsIcon from './icons/user-02-svgrepo-com copy.svg';
import carIcon from './icons/car-auto-svgrepo-com copy.svg';


const QuickFilters = ({ onFilterSelect }) => {
    const { t } = useTranslation();
    
    const quickFilters = [
        { label: t('automaticCar'), filter: { car_gearbox: ['automatic'] }, icon: carIcon, colorClass: 'automatic-color' },
        { label: t('electricCar'), filter: { car_fuel: ['electric'] }, icon: electricIcon, colorClass: 'electric-color' },
        { label: t('hybridCar'), filter: { car_fuel: ['hybrid'] }, icon: hybridIcon, colorClass: 'hybrid-color' },
        { label: t('7seatsCar'), filter: { car_seats: ['7'] }, icon: seatsIcon, colorClass: 'seats-color' },
        { label: t('max10000Car'), filter: { car_max_price: ['10000'] }, icon: priceIcon, colorClass: 'price-color' },
    ];
    
    const handleClick = (filter) => {
        if (onFilterSelect) {
            onFilterSelect('', filter);
        }
    };

    return (
        <div className="quick-filters">
            {quickFilters.map((item, index) => (
                <button
                    key={index}
                    className="quick-filter-button"
                    onClick={() => handleClick(item.filter)}
                >
                    {item.icon && <img src={item.icon} alt={item.label} className={`quick-filter-icon ${item.colorClass}`} />}
                    {item.label}
                </button>
            ))}
        </div>
    );
};

QuickFilters.propTypes = {
    onFilterSelect: PropTypes.func.isRequired,
};

export default QuickFilters;
