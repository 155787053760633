// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector) // Detecta el idioma preferido del navegador
  .use(initReactI18next)
  .init({
    fallbackLng: 'es', // Idioma por defecto
    debug: true,
    
    resources: {
      es: {
        translation: {
            "sellCar": "Vender coche",
            "carHistory": "Historial coche",
            "insurance": "Seguros coche",
            "news": "Noticias",
            "language": "Idioma",


            "findYourIdealCar": "Encuentra tu coche <span class='titulo-destacado'>ideal</span>",
            "analyzingSites": "Explora anuncios de toda Europa con nuestro buscador con Inteligencia Artificial.<br />Describe lo que buscas y nosotros lo encontramos por ti.",
            "analyzingSitesMobile": "Explora anuncios de toda Europa con nuestro buscador con Inteligencia Artificial.",
            
            "automaticCar": "Coches Automáticos",
            "electricCar": "Coches Eléctricos",
            "hybridCar": "Coches Híbridos",
            "7seatsCar": "Coches de 7 plazas",
            "max10000Car": "Menos 10,000€",


            "seeAll": "Ver todo",
            "advanced": "Avanzado",
            "searchText": "Búsqueda",
            "aiText": "AI",
            "searchPlaceholder1": "Busco coches baratos de menos de 10.000€ y de 5 plazas",
            "searchPlaceholder2": "Coches rojos de 2 plazas en Barcelona",
            "searchPlaceholder3": "Volswagen Golf GTI de menos de 50.000km",


            "searchByLifestyle": "Busca por <span class='titulo-destacado'>estilo de vida</span>",
            "city": "Ciudad",
            "sport": "Deportivo",
            "offroad": "Todoterreno",
            "family": "Familiar",
            "eco": "Ecológico",
            "searchByCarType": "Busca por <span class='titulo-destacado'>tipo de coche</span>",
            "suv": "SUV y 4x4",
            "sedan": "Berlina",
            "minivan": "Monovolumen",
            "urban": "Urbano",
            "sports": "Deportivo",
            "convertible": "Descapotable",
            "van": "Furgoneta",
            "searchByBrand": "Busca por <span class='titulo-destacado'>marca</span>",

            "removeFilters": "Borrar filtros",
            "location": "Ubicación",
            "brand": "Marca",
            "model": "Modelo",
            "gearbox": "Transmisión",
            "fuel": "Combustible",
            "price": "Precio",
            "year": "Año",
            "kilometers": "Kilómetros",
            "seller": "Vendedor",
            "doors": "Puertas",
            "seats": "Plazas",
            "color": "Color",
            "showCars": "Mostrar {{count}} coches",
            
            "carsFound": "coches encontrados",
            "filter": "Filtrar",
            "sort": "Ordenar",
            "relevant": "Más relevantes",
            "newest": "Más nuevos",
            "oldest": "Más antiguos",
            "highestMileage": "Más km",
            "lowestMileage": "Menos km",
            "highestPrice": "Más caros",
            "lowestPrice": "Más baratos",

            "filterPlaceholder": "Escriba para filtrar...",
            "popularBrands": "Marcas más populares",
            "otherBrands": "Otras marcas",
            "clearSelection": "Borrar Selección",
            "accept": "Aceptar",

            // Opciones filtros
            "Filters": {
              "car_gearbox": {
                "manual": "manual",
                "automático": "automático"
              },
            },

            
            // Sellcar
            "sellCarTitle": "Vende tu coche al <span class='titulo-destacado'>mejor</span> precio",
            "bestPrice": "mejor",
            "sellCarDescription": "Colaboramos con el mayor comprador de coches de Europa para que puedas vender tu coche de una forma rápida, fácil y segura.",
            "sellCarButton": "Vender coche",
            "howToSellTitle": "¿Cómo vender mi coche?",
            "step1Title": "1. Recibe una oferta real",
            "step1Description": "Recibe una oferta real basada en los datos que has introducido.",
            "step2Title": "2. Visita una sucursal",
            "step2Description": "Si decides vender, reserva una cita en una sucursal cercana.",
            "step3Title": "3. Recibe tu dinero",
            "step3Description": "Vende tu coche en menos de una hora.",

            // Car history
            "carHistoryTitle": "Conoce la <span class='titulo-destacado'>historia</span> de un vehículo",
            "carHistoryDescription": "Obtén un informe para evitar malos acuerdos y descubrir si un vehículo es seguro.",
            "carHistoryButton": "Obtener informe",
            "decideSafelyTitle": "Decide con seguridad",
            "advantage1Title": "Evita errores costosos",
            "advantage1Description": "Conoce el historial de cualquier vehículo para asegurarte de que no estás comprando una pérdida de dinero.",
            "advantage2Title": "Ahorra tiempo valioso",
            "advantage2Description": "Las pruebas de conducción llevan horas, así que elimina los inconvenientes antes de salir.",
            "advantage3Title": "Negocia un mejor acuerdo",
            "advantage3Description": "Una vez que hayas encontrado tu próximo vehículo, tendrás los datos para conseguir bajar ese precio.",

            // Insurance
            "insuranceTitle": "Comparador de <span class='titulo-destacado'>seguros</span> de coche",
            "insuranceDescription": "Encuentra el seguro de coche que más se adapte a tus necesidades.",
            "insuranceButton": "Calcula tu seguro",
            "advantagesTitle": "Ventajas",
            "advantage1Title2": "Ahorra más del 50% en el precio de tu seguro de coche.",
            "advantage1Description2": "Utilizando el comparador de seguros, puedes reducir significativamente el coste al contratar o renovar tu seguro de coche.",
            "advantage2Title2": "Compara precios de 30 aseguradoras al mismo tiempo.",
            "advantage2Description2": "En coches.com tienes acceso a todas las principales aseguradoras de coches, simplificando el proceso de cambio de seguro con nuestro comparador.",
            "advantage3Title2": "Calcula tu cuota en solo dos minutos.",
            "advantage3Description2": "Un proceso rápido y sencillo para obtener una cotización de seguro entre más de 30 compañías aseguradoras y ahorra hasta 600€ en el seguro de tu coche.",

            "newsTitle": "Noticias",
            "newsDescription": "Lo último en coches y tecnología automotriz. Explora las novedades del <br />mercado de la mano de especialistas en el sector.",
            "newsButton": "Ver más noticias",
            "newsError": "Hubo un error cargando las noticias:",
            "filterTodo": "Todo",
            "filterActualidad": "Actualidad",
            "filterRankings": "Rankings",
            "filterReviews": "Reviews",
            "filterConsejos": "Consejos",


            "links": "Enlaces",
            "legal": "Legal",
            "cookies": "Cookies",
            "privacyPolicy": "Política de Privacidad",
            "termsConditions": "Términos y Condiciones",
            "contact": "Contacto",
            "allRightsReserved": "Todos los derechos reservados."

        }
      },
      en: {
        translation: {
            "sellCar": "Sell Car",
            "carHistory": "Car History",
            "insurance": "Car Insurance",
            "news": "News",
            "language": "Language",


            "findYourIdealCar": "Find your <span class='titulo-destacado'>ideal</span> car",
            "analyzingSites": "Explore ads from across Europe with our AI-powered search engine.<br />Describe what you're looking for, and we'll find it for you.",
            "analyzingSitesMobile": "Explore ads from across Europe with our<br />AI-powered search engine.",

            "automaticCar": "Automatic Cars",
            "electricCar": "Electric Cars",
            "hybridCar": "Hybrid Cars",
            "7seatsCar": "7-Seater Cars",
            "max10000Car": "Under €10,000",


            "seeAll": "See all",
            "advanced": "Advanced",
            "searchText": "Search",
            "aiText": "AI",
            "searchPlaceholder1": "I'm looking for cheap cars under €10,000 with 5 seats",
            "searchPlaceholder2": "Red cars with 2 seats in Barcelona",
            "searchPlaceholder3": "Volkswagen Golf GTI with less than 50,000 km",


            "searchByLifestyle": "Search by <span class='titulo-destacado'>lifestyle</span>",
            "city": "City",
            "sport": "Sport",
            "offroad": "Off-road",
            "family": "Family",
            "eco": "Eco-friendly",
            "searchByCarType": "Search by <span class='titulo-destacado'>car type</span>",
            "suv": "SUV and 4x4",
            "sedan": "Sedan",
            "minivan": "Minivan",
            "urban": "Urban",
            "sports": "Sports",
            "convertible": "Convertible",
            "van": "Van",
            "searchByBrand": "Search by <span class='titulo-destacado'>brand</span>",


            "removeFilters": "Clear filters",
            "location": "Location",
            "brand": "Brand",
            "model": "Model",
            "gearbox": "Gearbox",
            "fuel": "Fuel",
            "price": "Price",
            "year": "Year",
            "kilometers": "Kilometers",
            "seller": "Seller",
            "doors": "Doors",
            "seats": "Seats",
            "color": "Color",
            "showCars": "Show {{count}} cars",

            "carsFound": "cars found",
            "filter": "Filter",
            "sort": "Sort",
            "relevant": "Most relevant",
            "newest": "Newest",
            "oldest": "Oldest",
            "highestMileage": "Highest mileage",
            "lowestMileage": "Lowest mileage",
            "highestPrice": "Highest price",
            "lowestPrice": "Lowest price",

            "filterPlaceholder": "Type to filter...",
            "popularBrands": "Popular Brands",
            "otherBrands": "Other Brands",
            "clearSelection": "Clear Selection",
            "accept": "Accept",

            "Filters": {
              "car_gearbox": {
                "manual": "manual",
                "automático": "automatic"
              }
            },


            "sellCarTitle": "Sell your car at the <span class='titulo-destacado'>best</span> price",
            "bestPrice": "best",
            "sellCarDescription": "We partner with the largest car buyer in Europe to help you sell your car quickly, easily, and securely.",
            "sellCarButton": "Sell car",
            "howToSellTitle": "How to sell my car?",
            "step1Title": "1. Receive a real offer",
            "step1Description": "Receive a real offer based on the details you provided.",
            "step2Title": "2. Visit a branch",
            "step2Description": "If you decide to sell, book an appointment at a nearby branch.",
            "step3Title": "3. Get your money",
            "step3Description": "Sell your car in under an hour.",

            "carHistoryTitle": "Know the <span class='titulo-destacado'>history</span> of a vehicle",
            "carHistoryDescription": "Get a report to avoid bad deals and find out if a vehicle is safe.",
            "carHistoryButton": "Get report",
            "decideSafelyTitle": "Decide safely",
            "advantage1Title": "Avoid costly mistakes",
            "advantage1Description": "Know any vehicle's history to make sure you're not buying a money pit.",
            "advantage2Title": "Save valuable time",
            "advantage2Description": "Test drives take hours, so eliminate deal-breakers before heading out.",
            "advantage3Title": "Negotiate a better deal",
            "advantage3Description": "Once you've found your next vehicle, you'll have the data to negotiate the price down.",

            "insuranceTitle": "Car insurance <span class='titulo-destacado'>comparator</span>",
            "insuranceDescription": "Find the car insurance that best suits your needs.",
            "insuranceButton": "Calculate your insurance",
            "advantagesTitle": "Advantages",
            "advantage1Title2": "Save over 50% on your car insurance price.",
            "advantage1Description2": "By using the insurance comparator, you can significantly reduce costs when taking out or renewing your car insurance.",
            "advantage2Title2": "Compare prices from 30 insurers at the same time.",
            "advantage2Description2": "On coches.com, you have access to all major car insurers, simplifying the insurance switch process with our comparator.",
            "advantage3Title2": "Calculate your premium in just two minutes.",
            "advantage3Description2": "A quick and easy process to get a quote from over 30 insurance companies and save up to €600 on your car insurance.",

            "newsTitle": "News",
            "newsDescription": "The latest in cars and automotive technology. Explore<br />market news from industry experts.",
            "newsButton": "See more news",
            "newsError": "There was an error loading the news:",
            "filterTodo": "All",
            "filterActualidad": "News",
            "filterRankings": "Rankings",
            "filterReviews": "Reviews",
            "filterConsejos": "Advice",


            "links": "Links",
            "legal": "Legal",
            "cookies": "Cookies",
            "privacyPolicy": "Privacy Policy",
            "termsConditions": "Terms and Conditions",
            "contact": "Contact",
            "allRightsReserved": "All rights reserved."
        }
      },
      fr: {
        translation: {
            "sellCar": "Vendre voiture",
            "carHistory": "Historique voiture",
            "insurance": "Assurance voiture",
            "news": "Nouvelles",
            "language": "Langue",


            "findYourIdealCar": "Trouvez votre voiture <span class='titulo-destacado'>idéale</span>",
            "analyzingSites": "Explorez des annonces dans toute l'Europe avec notre moteur de recherche doté d'intelligence<br />artificielle. Décrivez ce que vous recherchez et nous le trouverons pour vous.",
            "analyzingSitesMobile": "Explorez des annonces dans toute l'Europe avec notre moteur de recherche doté d'intelligence artificielle.",

            "automaticCar": "Voitures Automatiques",
            "electricCar": "Voitures Électriques",
            "hybridCar": "Voitures Hybrides",
            "7seatsCar": "Voitures 7 places",
            "max10000Car": "Moins de 10 000 €",

            "seeAll": "Voir tout",
            "advanced": "Avancé",
            "searchText": "Recherche",
            "aiText": "AI",
            "searchPlaceholder1": "Je cherche des voitures à moins de 10 000 € avec 5 places",
            "searchPlaceholder2": "Voitures rouges avec 2 places à Paris",
            "searchPlaceholder3": "Volkswagen Golf GTI avec moins de 50 000 km",


            "searchByLifestyle": "Cherchez par <span class='titulo-destacado'>style de vie</span>",
            "city": "Ville",
            "sport": "Sportif",
            "offroad": "Tout-terrain",
            "family": "Familial",
            "eco": "Écologique",
            "searchByCarType": "Rechercher par <span class='titulo-destacado'>type de voiture</span>",
            "suv": "SUV et 4x4",
            "sedan": "Berline",
            "minivan": "Monospace",
            "urban": "Urbain",
            "sports": "Sportif",
            "convertible": "Cabriolet",
            "van": "Fourgonnette",
            "searchByBrand": "Rechercher par <span class='titulo-destacado'>marque</span>",


            "removeFilters": "Effacer les filtres",
            "location": "Emplacement",
            "brand": "Marque",
            "model": "Modèle",
            "gearbox": "Transmission",
            "fuel": "Carburant",
            "price": "Prix",
            "year": "Année",
            "kilometers": "Kilomètres",
            "seller": "Vendeur",
            "doors": "Portes",
            "seats": "Places",
            "color": "Couleur",
            "showCars": "Afficher {{count}} voitures",

            "carsFound": "voitures trouvées",
            "filter": "Filtrer",
            "sort": "Trier",
            "relevant": "Les plus pertinents",
            "newest": "Les plus récents",
            "oldest": "Les plus anciens",
            "highestMileage": "Plus de km",
            "lowestMileage": "Moins de km",
            "highestPrice": "Les plus chers",
            "lowestPrice": "Les moins chers",

            "filterPlaceholder": "Tapez pour filtrer...",
            "popularBrands": "Marques les plus populaires",
            "otherBrands": "Autres marques",
            "clearSelection": "Effacer la sélection",
            "accept": "Accepter",

            "Filters": {
              "car_gearbox": {
                "manual": "manuel",
                "automático": "automatique"
              }
            },


            "sellCarTitle": "Vendez votre voiture au <span class='titulo-destacado'>meilleur</span> prix",
            "bestPrice": "meilleur",
            "sellCarDescription": "Nous collaborons avec le plus grand acheteur de voitures en Europe pour vous aider à vendre votre voiture rapidement, facilement et en toute sécurité.",
            "sellCarButton": "Vendre voiture",
            "howToSellTitle": "Comment vendre ma voiture ?",
            "step1Title": "1. Recevez une offre réelle",
            "step1Description": "Recevez une offre réelle basée sur les informations fournies.",
            "step2Title": "2. Visitez une succursale",
            "step2Description": "Si vous décidez de vendre, prenez rendez-vous dans une succursale proche.",
            "step3Title": "3. Recevez votre argent",
            "step3Description": "Vendez votre voiture en moins d'une heure.",

            "carHistoryTitle": "Découvrez l'<span class='titulo-destacado'>historique</span> d'un véhicule",
            "carHistoryDescription": "Obtenez un rapport pour éviter les mauvaises affaires et vérifier si un véhicule est sûr.",
            "carHistoryButton": "Obtenir un rapport",
            "decideSafelyTitle": "Décidez en toute sécurité",
            "advantage1Title": "Évitez les erreurs coûteuses",
            "advantage1Description": "Découvrez l'historique de tout véhicule pour éviter d'acheter un gouffre financier.",
            "advantage2Title": "Gagnez un temps précieux",
            "advantage2Description": "Les essais sur route prennent du temps, éliminez les risques avant de sortir.",
            "advantage3Title": "Négociez une meilleure affaire",
            "advantage3Description": "Une fois votre véhicule trouvé, vous aurez les données pour négocier le prix.",

            "insuranceTitle": "Comparateur d'<span class='titulo-destacado'>assurances</span> auto",
            "insuranceDescription": "Trouvez l'assurance auto qui répond le mieux à vos besoins.",
            "insuranceButton": "Calculez votre assurance",
            "advantagesTitle": "Avantages",
            "advantage1Title2": "Économisez plus de 50 % sur votre assurance auto.",
            "advantage1Description2": "En utilisant le comparateur d'assurances, vous pouvez réduire considérablement les coûts lors de la souscription ou du renouvellement de votre assurance auto.",
            "advantage2Title2": "Comparez les prix de 30 assureurs en même temps.",
            "advantage2Description2": "Sur coches.com, vous avez accès à toutes les grandes compagnies d'assurances auto, simplifiant le changement d'assurance grâce à notre comparateur.",
            "advantage3Title2": "Calculez votre prime en seulement deux minutes.",
            "advantage3Description2": "Un processus rapide et facile pour obtenir un devis d'assurance parmi plus de 30 compagnies et économiser jusqu'à 600€ sur votre assurance auto.",

            "newsTitle": "Actualités",
            "newsDescription": "Les dernières nouvelles en matière de voitures et de technologie automobile. <br />Explorez les nouveautés du marché avec des experts du secteur.",
            "newsButton": "Voir plus d'actualités",
            "newsError": "Une erreur est survenue lors du chargement des actualités :",
            "filterTodo": "Tout",
            "filterActualidad": "Actualités",
            "filterRankings": "Classements",
            "filterReviews": "Critiques",
            "filterConsejos": "Conseils",


            "links": "Liens",
            "legal": "Légal",
            "cookies": "Cookies",
            "privacyPolicy": "Politique de Confidentialité",
            "termsConditions": "Conditions Générales",
            "contact": "Contact",
            "allRightsReserved": "Tous droits réservés."
        }
      },
      de: {
        translation: {
            "sellCar": "Auto verkaufen",
            "carHistory": "Auto Historie",
            "insurance": "Autoversicherung",
            "news": "Nachrichten",
            "language": "Sprache",


            "findYourIdealCar": "Finden Sie Ihr <span class='titulo-destacado'>ideales</span> Auto",
            "analyzingSites": "Durchsuchen Sie Anzeigen aus ganz Europa mit unserer KI-gestützten Suchmaschine.<br />Beschreiben Sie, wonach Sie suchen, und wir finden es für Sie.",
            "analyzingSitesMobile": "Durchsuchen Sie Anzeigen aus ganz Europa mit unserer KI-gestützten Suchmaschine.",

            "automaticCar": "Automatikautos",
            "electricCar": "Elektroautos",
            "hybridCar": "Hybridautos",
            "7seatsCar": "7-Sitzer-Autos",
            "max10000Car": "Unter 10.000 €",

            
            "seeAll": "Alles sehen",
            "advanced": "Erweitert",
            "searchText": "Suche",
            "aiText": "AI",
            "searchPlaceholder1": "Ich suche günstige Autos unter 10.000 € mit 5 Sitzen",
            "searchPlaceholder2": "Rote Autos mit 2 Sitzen in Barcelona",
            "searchPlaceholder3": "Volkswagen Golf GTI mit weniger als 50.000 km",


            "searchByLifestyle": "Suchen nach <span class='titulo-destacado'>Lebensstil</span>",
            "city": "Stadt",
            "sport": "Sport",
            "offroad": "Geländewagen",
            "family": "Familie",
            "eco": "Ökologisch",
            "searchByCarType": "Suchen nach <span class='titulo-destacado'>Autotyp</span>",
            "suv": "SUV und 4x4",
            "sedan": "Limousine",
            "minivan": "Minivan",
            "urban": "Städtisch",
            "sports": "Sport",
            "convertible": "Cabrio",
            "van": "Transporter",
            "searchByBrand": "Suche nach <span class='titulo-destacado'>Marke</span>",

            "removeFilters": "Filter löschen",
            "location": "Standort",
            "brand": "Marke",
            "model": "Modell",
            "gearbox": "Getriebe",
            "fuel": "Kraftstoff",
            "price": "Preis",
            "year": "Jahr",
            "kilometers": "Kilometer",
            "seller": "Verkäufer",
            "doors": "Türen",
            "seats": "Sitze",
            "color": "Farbe",
            "showCars": "{{count}} Autos anzeigen",

            "carsFound": "autos gefunden",
            "filter": "Filtern",
            "sort": "Sortieren",
            "relevant": "Am relevantesten",
            "newest": "Neueste",
            "oldest": "Älteste",
            "highestMileage": "Höchster km-Stand",
            "lowestMileage": "Niedrigster km-Stand",
            "highestPrice": "Teuerste",
            "lowestPrice": "Günstigste",

            "filterPlaceholder": "Zum Filtern eingeben...",
            "popularBrands": "Beliebte Marken",
            "otherBrands": "Andere Marken",
            "clearSelection": "Auswahl löschen",
            "accept": "Akzeptieren",

            "Filters": {
              "car_gearbox": {
                "manual": "manuell",
                "automático": "automatisch"
              }
            },


            "sellCarTitle": "Verkaufen Sie Ihr Auto zum <span class='titulo-destacado'>besten</span> Preis",
            "bestPrice": "besten",
            "sellCarDescription": "Wir arbeiten mit dem größten Autokäufer Europas zusammen, damit Sie Ihr Auto schnell, einfach und sicher verkaufen können.",
            "sellCarButton": "Auto verkaufen",
            "howToSellTitle": "Wie verkaufe ich mein Auto?",
            "step1Title": "1. Erhalten Sie ein echtes Angebot",
            "step1Description": "Erhalten Sie ein echtes Angebot auf Basis der von Ihnen eingegebenen Daten.",
            "step2Title": "2. Besuchen Sie eine Filiale",
            "step2Description": "Wenn Sie verkaufen möchten, vereinbaren Sie einen Termin in einer Filiale in Ihrer Nähe.",
            "step3Title": "3. Erhalten Sie Ihr Geld",
            "step3Description": "Verkaufen Sie Ihr Auto in weniger als einer Stunde.",

            "carHistoryTitle": "Erfahren Sie die <span class='titulo-destacado'>Historie</span> eines Fahrzeugs",
            "carHistoryDescription": "Erhalten Sie einen Bericht, um schlechte Geschäfte zu vermeiden und die Sicherheit eines Fahrzeugs zu überprüfen.",
            "carHistoryButton": "Bericht erhalten",
            "decideSafelyTitle": "Entscheiden Sie sicher",
            "advantage1Title": "Vermeiden Sie teure Fehler",
            "advantage1Description": "Erfahren Sie die Historie eines Fahrzeugs, um sicherzustellen, dass Sie keinen Reinfall kaufen.",
            "advantage2Title": "Sparen Sie wertvolle Zeit",
            "advantage2Description": "Probefahrten dauern Stunden, vermeiden Sie Risiken vorab.",
            "advantage3Title": "Handeln Sie einen besseren Preis aus",
            "advantage3Description": "Mit dem Bericht können Sie einen besseren Preis für Ihr nächstes Auto aushandeln.",

            "insuranceTitle": "Autoversicherungs-<span class='titulo-destacado'>vergleich</span>",
            "insuranceDescription": "Finden Sie die Autoversicherung, die am besten zu Ihren Bedürfnissen passt.",
            "insuranceButton": "Berechnen Sie Ihre Versicherung",
            "advantagesTitle": "Vorteile",
            "advantage1Title2": "Sparen Sie über 50 % bei Ihrer Kfz-Versicherung.",
            "advantage1Description2": "Mit dem Versicherungsvergleich können Sie die Kosten bei Abschluss oder Erneuerung Ihrer Autoversicherung erheblich senken.",
            "advantage2Title2": "Vergleichen Sie gleichzeitig die Preise von 30 Versicherern.",
            "advantage2Description2": "Auf coches.com haben Sie Zugang zu allen großen Autoversicherern und vereinfachen mit unserem Vergleichsrechner den Versicherungswechsel.",
            "advantage3Title2": "Berechnen Sie Ihre Prämie in nur zwei Minuten.",
            "advantage3Description2": "Ein schneller und einfacher Prozess, um ein Angebot von über 30 Versicherungsgesellschaften zu erhalten und bis zu 600 € bei Ihrer Kfz-Versicherung zu sparen.",

            "newsTitle": "Autonachrichten",
            "newsDescription": "Die neuesten Informationen über Autos und Automobiltechnik. <br />Entdecken Sie Marktnachrichten von Branchenexperten.",
            "newsButton": "Weitere Nachrichten anzeigen",
            "newsError": "Beim Laden der Nachrichten ist ein Fehler aufgetreten:",
            "filterTodo": "Alle",
            "filterActualidad": "Nachrichten",
            "filterRankings": "Ranglisten",
            "filterReviews": "Bewertungen",
            "filterConsejos": "Tipps",


            "links": "Links",
            "legal": "Rechtliches",
            "cookies": "Cookies",
            "privacyPolicy": "Datenschutzrichtlinie",
            "termsConditions": "AGB",
            "contact": "Kontakt",
            "allRightsReserved": "Alle Rechte vorbehalten."
        }
      }
    }

  });

export default i18n;
