import Navbar from './Navbar';
import Home from './Home';
import Results_page from './Results_page'
import Result_page from './Result_page'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './Footer';
import SellCar_page from './SellCar_page';
import CarHistory_page from './CarHistory_page';
import DarkModeToggle from './DarkModeToggle';
import Insurance_page from './Insurace_page';
import Media_page from './Media_page';
import axios from 'axios';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

function App() {

  const { i18n } = useTranslation();
  const validLanguages = ['es', 'en', 'fr', 'de'];
  const [isLanguageInitialized, setIsLanguageInitialized] = useState(false);

  // Validar el idioma del navegador al inicializar i18n
  const detectedLanguage = i18n.language || navigator.language.split('-')[0]; // Idioma detectado
  const fallbackLanguage = 'en'; // Idioma predeterminado

  if (!validLanguages.includes(detectedLanguage)) {
    i18n.changeLanguage(fallbackLanguage);
  }

  // Hook para manejar el idioma basado en la URL
  const LanguageHandler = () => {
    const { lang } = useParams(); // Obtén el idioma de la URL

    useEffect(() => {
      if (validLanguages.includes(lang)) {
        i18n.changeLanguage(lang); // Cambiar al idioma en la URL
      } else {
        // Redirigir al idioma predeterminado si el idioma no es válido
        window.location.replace(`/${fallbackLanguage}`);
      }
    }, [lang, i18n]);
    return null; // No renderiza nada
  };

  // Función para obtener la IP del usuario
  const getUserIP = async () => {
    try {
      const response = await axios.get('https://api.ipify.org?format=json');
      return response.data.ip; // Devuelve la IP pública del usuario
    } catch (error) {
      console.error('Error al obtener la IP del usuario:', error);
      return null;
    }
  };

  // Función para obtener la ubicación por IP usando la API de IP Locator
  const getDefaultLanguageByIP = async () => {
    try {
      const userIP = await getUserIP(); // Obtén la IP del usuario
      if (!userIP) {
        console.warn('No se pudo obtener la IP del usuario. Usando idioma predeterminado.');
        return fallbackLanguage;
      }
  
      const response = await axios.get('https://api.iplocation.net/', {
        params: {
          cmd: 'ip-country',
          ip: userIP,
          format: 'json',
        },
      });
  
      if (response.data.response_code === "200") {
        const countryCode = response.data.country_code2; // Código de país
        console.log(`País detectado: ${response.data.country_name} (${countryCode})`);
  
        // Mapa de países a idiomas
        const countryLanguageMap = {
          FR: 'fr',
          ES: 'es',
          DE: 'de',
          US: 'en',
          GB: 'en',
          CA: 'en',
        };
  
        // Devuelve el idioma asociado o el idioma por defecto
        return countryLanguageMap[countryCode] || fallbackLanguage;
      } else {
        console.warn(`Error en la API: ${response.data.response_message}`);
        return fallbackLanguage;
      }
    } catch (error) {
      console.error('Error al obtener la ubicación:', error);
      return fallbackLanguage;
    }
  };

  // Inicializa el idioma basado en localStorage o IP
  useEffect(() => {
    const initializeLanguage = async () => {
      // Paso 1: Obtener el idioma del navegador
      const browserLanguage = navigator.language.split('-')[0].toLowerCase();
      if (validLanguages.includes(browserLanguage)) {
        i18n.changeLanguage(browserLanguage); // Usar idioma del navegador si es válido
        setIsLanguageInitialized(true);
        return;
      }
  
      // Paso 2: Intentar obtener el idioma del país detectado por IP
      const detectedLanguage = await getDefaultLanguageByIP();
      if (validLanguages.includes(detectedLanguage)) {
        i18n.changeLanguage(detectedLanguage); // Usar idioma detectado por IP si es válido
        setIsLanguageInitialized(true);
        return;
      }
  
      // Paso 3: Usar inglés como idioma por defecto
      i18n.changeLanguage(fallbackLanguage); // Usar inglés
      setIsLanguageInitialized(true);
    };
  
    initializeLanguage();
  }, []);
  

  if (!isLanguageInitialized) {
    return <div></div>; // Mostrar un loader mientras se inicializa el idioma
  }

  return (
      <Router>
        <div className="App">
          <Navbar />
          <div className="content">
            <Routes>
              {/*Home*/}
              <Route path="/:lang/" element={<Home />} />

              {/*Results_page*/}
              <Route path="/:lang/coches-segunda-mano" element={<Results_page />} />
              <Route path="/:lang/used-cars" element={<Results_page />} />
              <Route path="/:lang/voitures-occasion" element={<Results_page />} />
              <Route path="/:lang/gebrauchtwagen" element={<Results_page />} />

              {/*Media_page*/}
              <Route path="/:lang/noticias" element={<Media_page />} />
              <Route path="/:lang/news" element={<Media_page />} />
              <Route path="/:lang/actualites" element={<Media_page />} />
              <Route path="/:lang/nachrichten" element={<Media_page />} />

              {/*Result_page*/}
              <Route path="/:lang/listing" element={<Result_page />} />

              {/*Brands*/}
              <Route path="/:lang/:brand/coches-segunda-mano" element={<Results_page />} />
              <Route path="/:lang/:brand/used-cars" element={<Results_page />} />
              <Route path="/:lang/:brand/voitures-occasion" element={<Results_page />} />
              <Route path="/:lang/:brand/gebrauchtwagen" element={<Results_page />} />
              {/*Regions*/}
              <Route path="/:lang/coches-segunda-mano/:region" element={<Results_page />} />
              <Route path="/:lang/used-cars/:region" element={<Results_page />} />
              <Route path="/:lang/voitures-occasion/:region" element={<Results_page />} />
              <Route path="/:lang/gebrauchtwagen/:region" element={<Results_page />} />
              
              <Route
                path="/:lang/:brand/used-cars/:region"
                element={<Results_page />}
              />

              {/*Aux*/}
              <Route path="/:lang/sellcar" element={<SellCar_page />} />
              <Route path="/:lang/carhistory" element={<CarHistory_page />} />
              <Route path="/:lang/insurance" element={<Insurance_page />} />


              <Route path="/" element={<Navigate to="/en/" replace />} />
            </Routes>
          </div>
          {/*<DarkModeToggle/>*/}
          <p className="version">v1.22</p> 
          <Footer />
        </div>
      </Router>
  );
}

export default App;
