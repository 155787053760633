// src/Navbar.js
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from './logotest.png';
import { ReactComponent as GlobeIcon } from './icons/globe-svgrepo-com-2.svg';
import RegionSelector from './RegionSelector';
import icon_language from './icons/caret-down-md-svgrepo-com.svg';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [showRegionSelector, setShowRegionSelector] = useState(false);
  const [showLanguageMenu, setShowLanguageMenu] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const validLanguages = ['es', 'en', 'fr', 'de'];

  const routeTranslations = {
    usedCars: {
      es: 'coches-segunda-mano',
      en: 'used-cars',
      fr: 'voitures-occasion',
      de: 'gebrauchtwagen',
    },
    news: {
      es: 'noticias',
      en: 'news',
      fr: 'actualites',
      de: 'nachrichten',
    },
  };

  const toggleMenu = () => setIsOpen(!isOpen);
  const toggleRegionSelector = () => setShowRegionSelector(!showRegionSelector);
  const toggleLanguageMenu = () => setShowLanguageMenu(!showLanguageMenu);

  const getTranslatedRoute = (key, lang) => routeTranslations[key][lang] || routeTranslations[key].en;

  const selectLanguage = (language) => {
    const newLang = language.toLowerCase();
  
    if (!validLanguages.includes(newLang)) {
      return;
    }
  
    // Filtra las partes de la ruta para evitar segmentos vacíos
    const currentPathParts = location.pathname.split('/').slice(2).filter(Boolean);
    const translatedRoute = Object.keys(routeTranslations).find((key) =>
      Object.values(routeTranslations[key]).includes(currentPathParts[0])
    );
  
    const newRoute = translatedRoute ? getTranslatedRoute(translatedRoute, newLang) : '';
    const updatedPath = [`/${newLang}`, newRoute, ...currentPathParts.slice(1)]
      .filter(Boolean)
      .join('/'); // Une las partes sin barras redundantes
  
    navigate(updatedPath); // Navega a la nueva ruta correctamente
    i18n.changeLanguage(newLang);
  
    // Guardar el idioma seleccionado en localStorage
    localStorage.setItem('selectedLanguage', newLang);
  };
  

  const getLinkClass = (path) => (location.pathname === path ? 'active' : '');

  return (
    <div className="navbar-content">
      <nav className="navbar">
        <Link to={`/${i18n.language}`} className="logo-link">
          <img src={logo} alt="Logo" className="logo" />
        </Link>

        <div className={`links ${isOpen ? 'open' : ''}`}>
          <div className="centered-links">

            {/*<Link to="/sellcar" className={getLinkClass('/sellcar')}>{t('sellCar')}</Link>
            <Link to="/carhistory" className={getLinkClass('/carhistory')}>{t('carHistory')}</Link>
            <Link to="/insurance" className={getLinkClass('/insurance')}>{t('insurance')}</Link>*/}

            <Link
              to={`/${i18n.language}/${getTranslatedRoute('news', i18n.language)}`}
              className={getLinkClass(`/${i18n.language}/${getTranslatedRoute('news', i18n.language)}`)}
            >
              {t('news')}
            </Link>
          </div>

          <div className="header-buttons">
            <div className="language-menu">
              <button className="header-button" onClick={toggleLanguageMenu}>
                <img
                  src={icon_language}
                  alt="Icono de idioma"
                  className={`language-icon ${showLanguageMenu ? 'rotate' : ''}`}
                />
                {i18n.language.toUpperCase()}
              </button>
              {showLanguageMenu && (
                <ul className="language-dropdown">
                  <li onClick={() => selectLanguage('es')}>ES</li>
                  <li onClick={() => selectLanguage('en')}>EN</li>
                  <li onClick={() => selectLanguage('fr')}>FR</li>
                  <li onClick={() => selectLanguage('de')}>DE</li>
                </ul>
              )}
            </div>
          </div>
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          <div className={`hamburger ${isOpen ? 'open' : ''}`}></div>
          <div className={`hamburger ${isOpen ? 'open' : ''}`}></div>
        </div>
      </nav>
    </div>
  );
};


export default Navbar;
