import Filters from "./Filters";
import Car_list from "./Car_list";
import { useState, useEffect, useRef } from "react";
import SearchBar from "./SearchBar3";
import Center_header from "./Center_header";
import Center_header_mobile from "./Center_header_mobile";
import Filters_header from "./Filters_header";
import { useLocation, useNavigate } from 'react-router-dom';
import useFetch from "./useFetch";
import { ClipLoader } from 'react-spinners';
import isEqual from 'lodash/isEqual';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const Results_page = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const { brand, region } = useParams();
    const { i18n } = useTranslation();

    const routeTranslations = {
        usedCars: {
            es: 'coches-segunda-mano',
            en: 'used-cars',
            fr: 'voitures-occasion',
            de: 'gebrauchtwagen',
        },
    };

    const getTranslatedRoute = (key, lang) => {
        return routeTranslations[key][lang] || routeTranslations[key].en; // Por defecto usa inglés
    };
    
    // Estado inicial basado en la URL
    const initialQuery = searchParams.get('query') || '';
    const initialPage = parseInt(searchParams.get('page')) || 1;
    const initialOrder = searchParams.get('order') || 'relevant';
    const initialFilters = searchParams.get('filters')
    ? { 
        ...JSON.parse(searchParams.get('filters')), 
        ...(brand ? { car_brand: [brand] } : {}),
        ...(region ? { car_location_region: [region] } : {})
    }
    : {
        ...(brand ? { car_brand: [brand] } : {}),
        ...(region ? { car_location_region: [region] } : {})
    };

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const [query, setQuery] = useState(initialQuery);
    const [querySearchbar, setQuerySearchbar] = useState(initialQuery);
    

    const [page, setPage] = useState(initialPage);
    const [order, setOrder] = useState(initialOrder);
    const [resultsPerPage] = useState(20);
    const searchInputRef = useRef(null);
    /*Guarda estat filtres*/
    const [filters, setFilters] = useState(initialFilters);
    const [filtersBackend, setFiltersBackend] = useState({});
    const [filterOptions, setFilterOptions] = useState({});

    const [isBackendCallActive, setIsBackendCallActive] = useState(false);
    const [isInitialFetchComplete, setIsInitialFetchComplete] = useState(false);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    

    /*Estat per mostrar/ocultar filtres*/
    const [filtersVisible, setFiltersVisible] = useState(false);
    const fetchUrl = isBackendCallActive
    ? query
        ? `http://3.95.6.146:8080/new_search/?data=${encodeURIComponent(query)}${Object.keys(filters).length ? `&filters=${encodeURIComponent(JSON.stringify(filters))}` : ''}&page=${page}&results_per_page=${resultsPerPage}&sort_by=${order}`
        : `http://3.95.6.146:8080/new_search/?${Object.keys(filters).length ? `filters=${encodeURIComponent(JSON.stringify(filters))}` : ''}&page=${page}&results_per_page=${resultsPerPage}&sort_by=${order}`
    : null;
    console.log("Fetch URL generated:", fetchUrl);
    
    // Custom Hook para fetch
    const { data, isPending, error } = useFetch(fetchUrl);

    // UseEffect para manejar el cambio de `isBackendCallActive`
    useEffect(() => {
        if (isBackendCallActive && fetchUrl) {
            // Realiza la llamada y luego desactiva la variable auxiliar
            setIsBackendCallActive(false);
        }
    }, [isBackendCallActive, fetchUrl]);

    useEffect(() => {
        if (data && !isInitialFetchComplete) {
            setIsInitialFetchComplete(true); // Marca la carga inicial como completa
        }
    }, [data, isInitialFetchComplete]);

    
 
    // Actualizar los parámetros de la URL
    const updateURL = (newQuery, newPage, newOrder, newFilters) => {
        const currentLang = i18n.language;

        // Agregar filtros de marca y región si existen
        if (brand) {
            newFilters = { ...newFilters, car_brand: [brand] };
        }
        if (region) {
            newFilters = { ...newFilters, car_location_region: [region] };
        }

        // Traducir la ruta 'used-cars' según el idioma actual
        const translatedRoute = getTranslatedRoute('usedCars', currentLang);

        // Construir la URL traducida
        let url = `/${currentLang}/${translatedRoute}?query=${encodeURIComponent(newQuery)}&page=${newPage}&sort_by=${newOrder}`;
        if (Object.keys(newFilters).length) {
            url += `&filters=${encodeURIComponent(JSON.stringify(newFilters))}`;
        }

        // Navegar a la URL traducida
        navigate(url);
    };


    // Manejador de búsqueda
    const handleSearch = (newQuery, newFilters = filters) => {
        const cleanedFilters = cleanFilters(newFilters);
        setQuery(newQuery);
        setQuerySearchbar(newQuery);
        setFilters(cleanedFilters);
        setPage(1);
        updateURL(newQuery, 1, order, cleanedFilters);
        setIsBackendCallActive(true);
    };
     

    const handleOrderChange = (newOrder) => {
        setOrder(newOrder);
        updateURL(query, 1, newOrder, filters);
        setIsBackendCallActive(true);
    };
    
    // Lógica para manejar cambios en los filtros
    const handleFilterChange = (newFilters) => {
        const cleanedFilters = cleanFilters(newFilters);
        setFilters(cleanedFilters);
        setPage(1);
        updateURL(query, 1, order, cleanedFilters);
        setIsBackendCallActive(true);
    };

    /*Filtra el objeto de filtros para mantener únicamente los filtros con valores no vacíos*/
    const cleanFilters = (filters) => {
        const cleaned = {};
        Object.keys(filters).forEach((key) => {
            if (filters[key] && filters[key].length > 0) {
                cleaned[key] = filters[key];
            }
        });
        return cleaned;
    };

    /*elimina un filtre de l'objecte filter i fa una crida a handleFilterChange amb els filtres restants per fer una nova busqueda */
    const handleRemoveFilter = (filterKey, filterOption = null) => {
        console.log("Removing filter. Key:", filterKey, "Option:", filterOption);
    
        const newFilters = { ...filters };
    
        // Si se proporciona una opción específica y el filtro es un array
        if (filterOption) {
            newFilters[filterKey] = newFilters[filterKey]?.filter(option => option !== filterOption);
    
            // Si el filtro queda vacío, elimínalo completamente
            if (newFilters[filterKey]?.length === 0) delete newFilters[filterKey];

        } else {
            // Si no se proporciona opción, elimina todo el filtro
            delete newFilters[filterKey];
        }
    

        // Realiza la búsqueda con el nuevo estado
        handleFilterChange({ ...newFilters });

    };
    
    
    /*elimina tots els filtres i fa una nova busqueda*/
    const handleRemoveAllFilters = () => {
        console.log("Removing all filters");
    
        // Crear un nuevo estado de filtros
        const newFilters = {};
        setFilters(newFilters);
        setQuery("");
        setQuerySearchbar("");
        // Realizar la llamada para limpiar los filtros y actualizar la URL
        handleFilterChange(newFilters);

    };
    

    /*actulitza filterOptions*/
    const handleFilterOptionsUpdate = (newOptions) => {
        if (JSON.stringify(newOptions) !== JSON.stringify(filterOptions)) {
            console.log("Updating filter options:", newOptions);
            setFilterOptions(newOptions);
        }
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
        updateURL(query, newPage, order, filters);
        setIsBackendCallActive(true);
        window.scrollTo({
            top: 0, // Desplazarse al inicio
            
        });
    };

    useEffect(() => {
        // Sólo hacer focus si el ancho de pantalla es mayor a 768px (escritorio o tablet en modo horizontal)
        if (searchInputRef.current && window.innerWidth > 768) {
            searchInputRef.current.focus();
        }
    }, []);

    useEffect(() => {
        if (brand) {
            setFilters((prevFilters) => ({
                ...prevFilters,
                car_brand: [brand],
            }));
        }
    }, [brand]);


    useEffect(() => {
        if (data && data.query !== querySearchbar) {
            setQuery(data.query); // Actualiza query con el valor de data.query
        }
    }, [data, querySearchbar]);


    useEffect(() => {
        const newQuery = searchParams.get('query') || '';
        const newPage = parseInt(searchParams.get('page')) || 1;
        const newOrder = searchParams.get('order') || order;
        const newFilters = searchParams.get('filters') 
            ? JSON.parse(searchParams.get('filters')) 
            : {};
    
        // Mantener `car_brand` y `car_location_region` si están definidos en la URL
        if (brand) {
            newFilters.car_brand = [brand];
        }
        if (region) {
            newFilters.car_location_region = [region];
        }
    
        if (isInitialLoad) {
            // Actualiza el estado inicial basado en la URL
            setQuery(newQuery);
            setQuerySearchbar(newQuery); // Sincroniza con la barra de búsqueda
            setPage(newPage);
            setOrder(newOrder);
            setFilters(newFilters);
    
            // Una vez configurados los estados, activa la llamada inicial
            setTimeout(() => {
                setIsBackendCallActive(true); // Asegúrate de que todos los estados estén listos antes de hacer la llamada
            }, 0);
    
            setIsInitialLoad(false); // Marca la carga inicial como completada
        }
    }, [isInitialLoad, location.search, brand, region]);
    
    

    // Cuando recibes nuevos filtros del backend
    useEffect(() => {
        if (data && data.filters) {
            // Transformar los valores de data.filters a minúsculas
            const lowercaseFilters = Object.entries(data.filters).reduce((acc, [key, value]) => {
                acc[key] = Array.isArray(value)
                    ? value.map((v) => v.toLowerCase()) // Si es un array, convertir cada valor a minúscula
                    : typeof value === 'string'
                    ? value.toLowerCase()             // Si es un string, convertir a minúscula
                    : value;                          // Mantener valores que no sean string o array
                return acc;
            }, {});

            setFilters(lowercaseFilters); // Guardar los filtros transformados
        }
    }, [data]);

    
    

    const totalPages = data ? Math.ceil(data.total / resultsPerPage) : 0;

    const toggleFilters = () => {
        setFiltersVisible(!filtersVisible);
    };

    
    return (
        <div className="results_page">
            <div className="results_page-content">
                <aside className={`left ${filtersVisible ? 'visible' : ''}`}>
                    
                    <Filters 
                        /*filters={Object.keys(filtersBackend).length > 0 ? filtersBackend : filters} */
                        filters={filters}
                        onFilterChange={handleFilterChange}
                        onClose={toggleFilters}
                        onRemoveAllFilters={handleRemoveAllFilters}
                        totalCars={data ? data.total : 0}
                        onFilterOptionsUpdate={handleFilterOptionsUpdate}
                        query={query}
                        isInitialFetchComplete={isInitialFetchComplete}
                    />
                    
                    
                    <div className="results_page_banner">
                        <div className="results_page_banner2">
                            <p>Anunci</p>
                        </div>
                    </div>
                </aside>
                <main className="center">
                    <SearchBar onSearch={handleSearch} query={querySearchbar} ref={searchInputRef} />
                    <p className="AI-message">Este buscador funciona con Inteligencia Artificial y puede dar resultados inesperados. Ajusta los filtros si es necesario.</p>
                    
                    {isMobile ? (
                        <Center_header_mobile 
                            total={data ? data.total : 0} 
                            filters={filters}
                            order={order}
                            onToggleFilters={toggleFilters}
                            onOrderChange={handleOrderChange}
                            onRemoveFilter={handleRemoveFilter}
                            onRemoveAllFilters={handleRemoveAllFilters}
                            isPending={isPending}
                        />
                    ) : (
                        <Center_header
                            cars={data ? data.cars : []}
                            total={data ? data.total : 0}
                            order={order}
                            onOrderChange={handleOrderChange}
                            onToggleFilters={toggleFilters}
                            isPending={isPending}
                        />
                    )}
                    
                    {isMobile ? null : (
                        <Filters_header
                            filters={filters}
                            /*filters={Object.keys(filtersBackend).length > 0 ? filtersBackend : filters} */
                            onRemoveFilter={handleRemoveFilter} 
                            onRemoveAllFilters={handleRemoveAllFilters} 
                        />
                    )}


                    {isPending && (
                        <div className="loading-container">
                            <ClipLoader color="#5080ed" loading={true} />
                            <div className="loading-text"></div>
                        </div>
                    )}
                    {error && !isPending && (
                        <div className="error-message">
                            <p>There was an error loading the data: {error}</p>
                        </div>
                    )}
                    {data && data.cars && !isPending && !error && (
                        <>
                            {/*<Center_header cars={data.cars} total={data.total} order={order} onOrderChange={handleOrderChange} onToggleFilters={toggleFilters} />*/}

                            

                            
                            <Car_list cars={data.cars} />
                        </>
                    )}
                    {data && data.total > 0 && (
                        <div className="pagination">
                            <button className="prev-button" onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
                                &lt;
                            </button>
                            {page > 3 && (
                                <>
                                    <button onClick={() => handlePageChange(1)}>1</button>
                                    <span>...</span>
                                </>
                            )}
                            {page > 2 && <button onClick={() => handlePageChange(page - 2)}>{page - 2}</button>}
                            {page > 1 && <button onClick={() => handlePageChange(page - 1)}>{page - 1}</button>}
                            <button className="current-page" disabled>{page}</button>
                            {page < totalPages && <button onClick={() => handlePageChange(page + 1)}>{page + 1}</button>}
                            {page < totalPages - 1 && <button onClick={() => handlePageChange(page + 2)}>{page + 2}</button>}
                            {page < totalPages - 2 && (
                                <>
                                    <span>...</span>
                                    <button onClick={() => handlePageChange(totalPages)}>
                                        {totalPages}
                                    </button>
                                </>
                            )}
                            <button className="next-button" onClick={() => handlePageChange(page + 1)} disabled={page === totalPages}>
                                &gt;
                            </button>
                        </div>
                    )}
                </main>
                <aside className="right mobile-hidden">
                    <div className="results_page_banner">
                        <div className="results_page_banner2">
                            <p>Anunci</p>
                        </div>
                    </div>
                </aside>
            </div>
        </div>
    );
};

export default Results_page;
