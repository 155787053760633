import React, { useEffect, useRef } from "react";

const ScrollingText = () => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    let position = 0;

    const animate = () => {
      if (wrapper) {
        position -= 2; // Velocidad del desplazamiento
        // Reinicia la posición al final del ciclo para que sea continuo
        if (Math.abs(position) >= wrapper.offsetWidth / 2) {
          position = 0;
        }
        wrapper.style.transform = `translateX(${position}px)`;
      }
      requestAnimationFrame(animate);
    };

    animate();

    return () => cancelAnimationFrame(animate);
  }, []);

  return (
    <div className="scrolling-text-container">
      <div className="scrolling-text-wrapper" ref={wrapperRef}>
        <div className="scrolling-text">Encuentra las mejores ofertas</div>
        <div className="scrolling-text">Encuentra las mejores ofertas</div>
      </div>
    </div>
  );
};

export default ScrollingText;
