import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import MultiSelect from './MultiSelect2';
import MultiSelect_brands from './MultiSelect_brands';
import OneSelect from './OneSelect2';
import RangeSelect2 from './RangeSelect2';
import CountrySelect from './CountrySelect2';
import RegionSelect from './RegionSelect2';

/*filters icons*/
import countryIcon from './icons/globe-svgrepo-com.svg';
import brandIcon from './icons/car-auto-svgrepo-com.svg';
import modelIcon from './icons/adjustment-svgrepo-com.svg';
import gearboxIcon from './icons/settings-svgrepo-com.svg';
import priceIcon from './icons/tag-svgrepo-com.svg';
import yearIcon from './icons/calendar-check-svgrepo-com.svg';
import kmIcon from './icons/chart-bar-vertical-01-svgrepo-com.svg';
import fuelIcon from './icons/water-drop-svgrepo-com.svg';
import sellerIcon from './icons/shield-check-svgrepo-com.svg';
import doorsIcon from './icons/car-door-left-1-svgrepo-com.svg';
import seatsIcon from './icons/user-02-svgrepo-com.svg';
import colorIcon from './icons/brush-svgrepo-com.svg';

/*country icons*/
import spainIcon from './icons/icon_flags/flag-es-svgrepo-com.svg';
import franceIcon from './icons/icon_flags/flag-wf-svgrepo-com.svg';
import germanyIcon from './icons/icon_flags/flag-de-svgrepo-com.svg';
import ukIcon from './icons/icon_flags/flag-gb-svgrepo-com.svg';
import europeIcon from './icons/icon_flags/flag-eu-svgrepo-com.svg';

/*brand icons*/
import Audi_icon from './brand_icons/audi.svg';
import Bmw from './brand_icons/bmw.svg';
import Citroen from './brand_icons/citroen.svg';
import Ford from './brand_icons/ford.svg';
import Infiniti from './brand_icons/infiniti.svg';
import Jaguar from './brand_icons/jaguar.svg';
import Land_rover from './brand_icons/land-rover.svg';
import Mitsubishi from './brand_icons/mitsubishi.svg';
import Opel from './brand_icons/opel.svg';
import Porsche from './brand_icons/porsche.svg';
import Toyota from './brand_icons/toyota.svg';
import Volkswagen from './brand_icons/volkswagen.svg';
import Volvo from './brand_icons/volvo.svg';
import Alfa_romeo from './brand_icons/alfa-romeo-alt.svg';
import Hyundai from './brand_icons/hyundai.svg';
import Jeep from './brand_icons/jeep.svg';
import Seat from './brand_icons/seat.svg';
import Tesla from './brand_icons/tesla.svg';
import Mazda from './brand_icons/mazda.svg';
import Honda from './brand_icons/honda.svg';
import Mclaren from './brand_icons/mclaren.svg';
import Mini from './brand_icons/mini.svg';
import Smart from './brand_icons/smart.svg';
import Subaru from './brand_icons/subaru.svg';
import Suzuki from './brand_icons/suzuki.svg';
import Mercedes from './brand_icons/mercedes.svg';
import Cupra from './brand_icons/cupra.svg';
import Dacia from './brand_icons/dacia.svg';

import Nissan from './brand_icons/nissan.svg';
import Peugeot from './brand_icons/peugeot.svg';
import Renault from './brand_icons/renault.svg';
import Kia from './brand_icons/kia.svg';

/*gearbox icons*/
import automaticIcon from './icons/automatic_icon.svg'
import manualIcon from './icons/manual_icon.svg'

/*colors icons*/
import redIcon from './icons/icon_colors/red-circle-svgrepo-com.svg'
import greenIcon from './icons/icon_colors/green-circle-svgrepo-com.svg'
import blackIcon from './icons/icon_colors/black-circle-svgrepo-com.svg'
import yellowIcon from './icons/icon_colors/yellow-circle-svgrepo-com.svg'
import greyIcon from './icons/icon_colors/grey-icon.svg'
import purpleIcon from './icons/icon_colors/purple-circle-svgrepo-com.svg'
import orangeIcon from './icons/icon_colors/orange-circle-svgrepo-com.svg'
import blueIcon from './icons/icon_colors/blue-circle-svgrepo-com.svg'
import brownIcon from './icons/icon_colors/brown-circle-svgrepo-com.svg'
import whiteIcon from './icons/icon_colors/white-icon.svg'

const optionIcons = {
    car_location_country: {
        "spain": spainIcon,
        "france": franceIcon,
        "uk": ukIcon,
        "germany": germanyIcon,
    },
    
    
    car_gearbox: {
        "automatic": automaticIcon,
        "manual": manualIcon
    },

    car_color: {
        "red": redIcon,
        "yellow": yellowIcon,
        "black": blackIcon,
        "purple": purpleIcon,
        "grey": greyIcon,
        "brown": brownIcon,
        "blue": blueIcon,
        "orange": orangeIcon,
        "white": whiteIcon,
        "green": greenIcon
    },
    car_brand: {
        "audi": Audi_icon,
        "bmw": Bmw,
        "citroen": Citroen,
        "ford": Ford,
        "infiniti": Infiniti,
        "jaguar": Jaguar,
        "land rover": Land_rover,
        "mitsubishi": Mitsubishi,
        "opel": Opel,
        "porsche": Porsche,
        "toyota": Toyota,
        "volkswagen": Volkswagen,
        "volvo": Volvo,
        "alfa romeo": Alfa_romeo,
        "hyundai": Hyundai,
        "jeep": Jeep,
        "seat": Seat,
        "tesla": Tesla,
        "mazda": Mazda,
        "honda": Honda,
        "mclaren": Mclaren,
        "mini": Mini,
        "smart": Smart,
        "subaru": Subaru,
        "suzuki": Suzuki,
        "mercedes": Mercedes,
        "cupra": Cupra,
        "nissan": Nissan,
        "dacia": Dacia,
        "renault": Renault,
        "kia": Kia,
        "peugeot": Peugeot
    },
};
const predefinedPriceRangeOptions = [
    { value: '1000', label: '1.000 €' },
    { value: '2000', label: '2.000 €' },
    { value: '3000', label: '3.000 €' },
    { value: '4000', label: '4.000 €' },
    { value: '5000', label: '5.000 €' },
    { value: '6000', label: '6.000 €' },
    { value: '7000', label: '7.000 €' },
    { value: '8000', label: '8.000 €' },
    { value: '9000', label: '9.000 €' },
    { value: '10000', label: '10.000 €' },
    { value: '11000', label: '11.000 €' },
    { value: '12000', label: '12.000 €' },
    { value: '13000', label: '13.000 €' },
    { value: '14000', label: '14.000 €' },
    { value: '15000', label: '15.000 €' },
    { value: '16000', label: '16.000 €' },
    { value: '17000', label: '17.000 €' },
    { value: '18000', label: '18.000 €' },
    { value: '19000', label: '19.000 €' },
    { value: '20000', label: '20.000 €' },
    { value: '21000', label: '21.000 €' },
    { value: '22000', label: '22.000 €' },
    { value: '23000', label: '23.000 €' },
    { value: '24000', label: '24.000 €' },
    { value: '25000', label: '25.000 €' },
    { value: '30000', label: '30.000 €' },
    { value: '35000', label: '35.000 €' },
    { value: '40000', label: '40.000 €' },
    { value: '50000', label: '50.000 €' },
    { value: '60000', label: '60.000 €' },
    { value: '70000', label: '70.000 €' },
];

const predefinedKmRangeOptions = [
    { value: '2500', label: '2.500 km' },
    { value: '5000', label: '5.000 km' },
    { value: '10000', label: '10.000 km' },
    { value: '20000', label: '20.000 km' },
    { value: '30000', label: '30.000 km' },
    { value: '40000', label: '40.000 km' },
    { value: '50000', label: '50.000 km' },
    { value: '60000', label: '60.000 km' },
    { value: '70000', label: '70.000 km' },
    { value: '80000', label: '80.000 km' },
    { value: '90000', label: '90.000 km' },
    { value: '100000', label: '100.000 km' },
    { value: '125000', label: '125.000 km' },
    { value: '150000', label: '150.000 km' },
    { value: '175000', label: '175.000 km' },
    { value: '200000', label: '200.000 km' },
];

const predefinedYearRangeOptions = [
    { value: '2025', label: '2025' },
    { value: '2024', label: '2024' },
    { value: '2023', label: '2023' },
    { value: '2022', label: '2022' },
    { value: '2021', label: '2021' },
    { value: '2020', label: '2020' },
    { value: '2019', label: '2019' },
    { value: '2018', label: '2018' },
    { value: '2017', label: '2017' },
    { value: '2016', label: '2016' },
    { value: '2015', label: '2015' },
    { value: '2014', label: '2014' },
    { value: '2013', label: '2013' },
    { value: '2012', label: '2012' },
    { value: '2011', label: '2011' },
    { value: '2010', label: '2010' },
    { value: '2009', label: '2009' },
    { value: '2008', label: '2008' },
    { value: '2007', label: '2007' },
    { value: '2006', label: '2006' },
    { value: '2005', label: '2005' },
    { value: '2004', label: '2004' },
    { value: '2003', label: '2003' },
    { value: '2002', label: '2002' },
    { value: '2001', label: '2001' },
    { value: '2000', label: '2000' },
    { value: '1999', label: '1999' },
    { value: '1998', label: '1998' },
    { value: '1997', label: '1997' },
    { value: '1996', label: '1996' },
    { value: '1995', label: '1995' },
    { value: '1994', label: '1994' },
    { value: '1993', label: '1993' },
    { value: '1992', label: '1992' },
    { value: '1991', label: '1991' },
    { value: '1990', label: '1990' },
    { value: '1989', label: '1989' },
    { value: '1988', label: '1988' },
    { value: '1987', label: '1987' },
    { value: '1986', label: '1986' },
    { value: '1985', label: '1985' },
    { value: '1984', label: '1984' },
    { value: '1983', label: '1983' },
    { value: '1982', label: '1982' },
    { value: '1981', label: '1981' },
    { value: '1980', label: '1980' },
    { value: '1979', label: '1979' },
    { value: '1978', label: '1978' },
    { value: '1977', label: '1977' },
    { value: '1976', label: '1976' },
    { value: '1975', label: '1975' },
    { value: '1974', label: '1974' },
    { value: '1973', label: '1973' },
    { value: '1972', label: '1972' },
    { value: '1971', label: '1971' },
    { value: '1970', label: '1970' },
    { value: '1969', label: '1969' },
    { value: '1968', label: '1968' },
    { value: '1967', label: '1967' },
    { value: '1966', label: '1966' },
    { value: '1965', label: '1965' },
    { value: '1964', label: '1964' },
    { value: '1963', label: '1963' },
    { value: '1962', label: '1962' },
    { value: '1961', label: '1961' },
    { value: '1960', label: '1960' },
];




const Filters = ({ filters, onFilterChange, onClose, onRemoveAllFilters, totalCars, onFilterOptionsUpdate, query, isUpdatingFiltersFromBackend, isInitialFetchComplete={isInitialFetchComplete}}) => {
    
    const { t } = useTranslation();

    /**ESTAT COMPONENT**/

    /*guarda els filtres seleccionats per l'usuari + copia de "filters"*/
    const [internalFilters, setInternalFilters] = useState(filters);
    /*guarda les opcions de cada filtre del backend*/
    const [filterOptions, setFilterOptions] = useState({
        car_location_country: [
            { value: "spain", label: t("Filters.car_location_country.spain", "spain"), icon: optionIcons.car_location_country["spain"] },
            { value: "france", label: t("Filters.car_location_country.france", "france"), icon: optionIcons.car_location_country["france"] },
            { value: "united kingdom", label: t("Filters.car_location_country.uk", "united kingdom"), icon: optionIcons.car_location_country["uk"] },
            { value: "germany", label: t("Filters.car_location_country.germany", "germany"), icon: optionIcons.car_location_country["germany"] },
        ],

        car_location_region: [],

        car_brand: [
            { value: "audi", label: "audi", icon: Audi_icon },
            { value: "bmw", label: "bmw", icon: Bmw },
            { value: "citroen", label: "citroen", icon: Citroen },
            { value: "ford", label: t("Filters.car_brand.ford", "ford"), icon: Ford },
            { value: "infiniti", label: t("Filters.car_brand.infiniti", "infiniti"), icon: Infiniti },
            { value: "jaguar", label: t("Filters.car_brand.jaguar", "jaguar"), icon: Jaguar },
            { value: "land rover", label: t("Filters.car_brand.land_rover", "land rover"), icon: Land_rover },
            { value: "mitsubishi", label: t("Filters.car_brand.mitsubishi", "mitsubishi"), icon: Mitsubishi },
            { value: "opel", label: t("Filters.car_brand.opel", "opel"), icon: Opel },
            { value: "porsche", label: t("Filters.car_brand.porsche", "porsche"), icon: Porsche },
            { value: "toyota", label: t("Filters.car_brand.toyota", "toyota"), icon: Toyota },
            { value: "volkswagen", label: t("Filters.car_brand.volkswagen", "volkswagen"), icon: Volkswagen },
            { value: "volvo", label: t("Filters.car_brand.volvo", "volvo"), icon: Volvo },
            { value: "alfa romeo", label: t("Filters.car_brand.alfa_romeo", "alfa romeo"), icon: Alfa_romeo },
            { value: "hyundai", label: t("Filters.car_brand.hyundai", "hyundai"), icon: Hyundai },
            { value: "jeep", label: t("Filters.car_brand.jeep", "jeep"), icon: Jeep },
            { value: "seat", label: t("Filters.car_brand.seat", "seat"), icon: Seat },
            { value: "tesla", label: t("Filters.car_brand.tesla", "tesla"), icon: Tesla },
            { value: "mazda", label: t("Filters.car_brand.mazda", "mazda"), icon: Mazda },
            { value: "honda", label: t("Filters.car_brand.honda", "honda"), icon: Honda },
            { value: "mclaren", label: t("Filters.car_brand.mclaren", "mclaren"), icon: Mclaren },
            { value: "mini", label: t("Filters.car_brand.mini", "mini"), icon: Mini },
            { value: "smart", label: t("Filters.car_brand.smart", "smart"), icon: Smart },
            { value: "subaru", label: t("Filters.car_brand.subaru", "subaru"), icon: Subaru },
            { value: "suzuki", label: t("Filters.car_brand.suzuki", "suzuki"), icon: Suzuki },
            { value: "mercedes", label: t("Filters.car_brand.mercedes", "mercedes"), icon: Mercedes },
            { value: "cupra", label: t("Filters.car_brand.cupra", "cupra"), icon: Cupra },
            { value: "dacia", label: t("Filters.car_brand.dacia", "dacia"), icon: Dacia },
        ],
        
        car_model: [
        ],
        
        car_gearbox: [
            { value: "manual", label: t("Filters.car_gearbox.manual", "manual"), icon: optionIcons.car_gearbox["manual"] },
            { value: "automatic", label: t("Filters.car_gearbox.automatic", "automatic"), icon: optionIcons.car_gearbox["automatic"] },
        ],
        car_fuel: [
            { value: "diesel", label: t("Filters.car_fuel.Diesel", "Diesel") },
            { value: "gasoline", label: t("Filters.car_fuel.Gasoline", "Gasoline") },
            { value: "electric", label: t("Filters.car_fuel.Electric", "Electric") },
            { value: "hybrid", label: t("Filters.car_fuel.Hybrid", "Hybrid") },
            { value: "hybrid-Electric", label: t("Filters.car_fuel.Hybrid-Electric", "Hybrid-Electric") },
            { value: "plug-in Hybrid", label: t("Filters.car_fuel.Plug-in Hybrid", "Plug-in Hybrid") },
            { value: "hydrogen", label: t("Filters.car_fuel.Hydrogen", "Hydrogen") },
            { value: "gas", label: t("Filters.car_fuel.Gas", "Gas") },
            { value: "cng", label: t("Filters.car_fuel.CNG", "CNG") },
            { value: "clp", label: t("Filters.car_fuel.CLP", "CLP") },
            { value: "other", label: t("Filters.car_fuel.Other", "Other") },
            { value: "unknown", label: t("Filters.car_fuel.Unknown", "Unknown") }
        ],
        
        car_min_price: [],
        car_max_price: [],
        car_min_km: [],
        car_max_km: [],
        car_min_year: [],
        car_max_year: [],
        car_doors: [
            { value: "2/3", label: t("Filters.car_doors.2/3", "2/3") },
            { value: "4/5", label: t("Filters.car_doors.4/5", "4/5") },
            { value: "6/7", label: t("Filters.car_doors.6/7", "6/7") }
        ],
        
        car_seller_type: [
            { value: "dealer", label: "dealer"},
            { value: "private", label: "private"},
        ],

        car_seats: [
            { value: "2", label: "2 seats"},
            { value: "3", label: "3 seats"},
            { value: "4", label: "4 seats"},
            { value: "5", label: "5 seats"},
            { value: "6", label: "6 seats"},
            { value: "7", label: "7 seats"}
        ],
        
        car_color: [
            { value: 'red', label: 'red', icon: optionIcons.car_color['red'] },
            { value: 'yellow', label: 'yellow', icon: optionIcons.car_color['yellow'] },
            { value: 'black', label: 'black', icon: optionIcons.car_color['black'] },
            { value: 'white', label: 'white', icon: optionIcons.car_color['white'] },
            { value: 'blue', label: 'blue', icon: optionIcons.car_color['blue'] },
            { value: 'purple', label: 'purple', icon: optionIcons.car_color['purple'] },
            { value: 'grey', label: 'grey', icon: optionIcons.car_color['grey'] },
            { value: 'brown', label: 'brown', icon: optionIcons.car_color['brown'] },
            { value: 'orange', label: 'orange', icon: optionIcons.car_color['orange'] },
            { value: 'green', label: 'green', icon: optionIcons.car_color['green'] }
        ]

    });
    /*indica si les opcions dels filtres s'estan carregant*/
    const [isLoadingCars, setIsLoadingCars] = useState(true);


    /*EFECTES SECUNDARIS*/

    /*sincronització filtres interns quan el propt filters canvia*/
    useEffect(() => {
        setInternalFilters(filters);
    }, [filters]);



    /*FUNCIONS PRINCIPALS*/

    /*Maneja cambios en los filtros seleccionados y los sincroniza con el estado interno y la prop onFilterChange*/
    const handleFilterChange = (selectedOptions, { name }) => {
        const newFilters = { ...internalFilters, [name]: selectedOptions };
        setInternalFilters(newFilters);
    
        // Solo notificamos cambios si no provienen del backend
        if (!isUpdatingFiltersFromBackend) {
            onFilterChange(newFilters);
        }
    };
    

    /*Específica para los filtros de rango (precio, año, kilómetros), actualiza los valores mínimo y máximo.*/
    const handleRangeChange = (selectedRange, { minName, maxName }) => {
        const newFilters = {
            ...internalFilters,
            [minName]: selectedRange.min,
            [maxName]: selectedRange.max
        };
        setInternalFilters(newFilters);
        onFilterChange(newFilters);
        console.log("Selected Range:", selectedRange);
    };

    /*Realiza una llamada al backend para obtener las opciones de filtros disponibles en base a los filtros actuales. */
    useEffect(() => {
        const fetchFilterOptions = async () => {
            try {
                setIsLoadingCars(true);

                // Asegurar que query no sea null
                const safeQuery = query ?? ""; 

                const optionsFromBackend = await getFilterOptions(internalFilters, safeQuery, t, filterOptions);
            
                setFilterOptions(prevOptions => ({
                    ...prevOptions,
                    ...optionsFromBackend, // Actualiza las opciones dinámicas
                }));
            } catch (error) {
                console.error('Error fetching filter options:', error);
            } finally {
                setIsLoadingCars(false);
            }
        };
    
        if (isInitialFetchComplete) { // Verifica si la llamada inicial está completada
            fetchFilterOptions();
        }
    }, [internalFilters]);
    


    /*RENDERITZACIÓ DE FILTRES*/
    const renderRangeSelect = (minName, maxName, value, options, placeholder, icon) => (
        <div className="select-container">
            <RangeSelect2
                options={options}
                placeholder={placeholder}
                onChange={selected => handleRangeChange(selected, { minName, maxName })}
                minValue={internalFilters[minName] || ''}
                maxValue={internalFilters[maxName] || ''}
                icon={icon}
            />
        </div>
    );

    const renderMultiSelect = (name, value, options, placeholder, icon, isDisabled = false) => (
        <div className="select-container">
            {internalFilters[name] && internalFilters[name].length > 0 && <label className="placeholder-label">{placeholder}</label>}
            <MultiSelect
                options={options.map(option => ({
                    ...option,
                    icon: optionIcons[name] ? optionIcons[name][option.label.split(" ")[0]] : null // Asigna ícono si existe
                }))}
                value={value}
                onChange={selected => handleFilterChange(selected, { name })}
                placeholder={placeholder}
                icon={icon}
                isLoading={isLoadingCars}  // Pasar el estado de carga al componente MultiSelect
                isDisabled={isDisabled}
            />
        </div>
    );

    const renderMultiSelect_brands = (name, value, options, placeholder, icon) => (
        <div className="select-container">
            {internalFilters[name] && internalFilters[name].length > 0 && <label className="placeholder-label">{placeholder}</label>}
            <MultiSelect_brands
                options={options.map(option => ({
                    ...option,
                    icon: optionIcons[name] ? optionIcons[name][option.label.split(" ")[0]] : null // Asigna ícono si existe
                }))}
                value={value}
                onChange={selected => handleFilterChange(selected, { name })}
                placeholder={placeholder}
                icon={icon}
                isLoading={isLoadingCars}  // Pasar el estado de carga al componente MultiSelect
            />
        </div>
    );

    const renderCountrySelect = (name, value, options, placeholder, icon) => (
        <div className="select-container">
            {internalFilters[name] && internalFilters[name].length > 0 && <label className="placeholder-label">{placeholder}</label>}
            <CountrySelect
                options={options}
                value={value}
                onChange={selected => handleFilterChange(selected, { name })}
                placeholder={placeholder}
                icon={icon}
                isLoading={isLoadingCars}
            />
        </div>
    );

    const renderRegionSelect = (name, value, options, placeholder, icon) => (
        <div className="select-container">
            {internalFilters[name] && internalFilters[name].length > 0 && <label className="placeholder-label">{placeholder}</label>}
            <RegionSelect
                options={options}
                value={value}
                onChange={selected => handleFilterChange(selected, { name })}
                placeholder={placeholder}
                icon={icon}
                isLoading={isLoadingCars}
            />
        </div>
    );

    const renderOneSelect = (name, value, options, placeholder, icon) => (
        <div className="select-container">
            {internalFilters[name] && internalFilters[name].length > 0 && <label className="placeholder-label">{placeholder}</label>}
            <OneSelect
                options={options.map(option => ({
                    ...option,
                    icon: optionIcons[name] ? optionIcons[name][option.label.split(" ")[0]] : null // Asigna ícono si existe
                }))}
                value={value}
                onChange={selected => handleFilterChange(selected, { name })}
                placeholder={placeholder}
                icon={icon}
                isLoading={isLoadingCars}  // Pasar el estado de carga al componente OneSelect
            />
        </div>
    );


    return (
        <div className="filters_menu">
            <div className="filters_location">
                <h4>Buscando en:</h4>
                <div className="filter">{renderCountrySelect("car_location_country", internalFilters.car_location_country, filterOptions.car_location_country, t('Europe'), europeIcon)}</div>
                <div className="filter">{renderRegionSelect("car_location_region", internalFilters.car_location_region, filterOptions.car_location_region, t('Select Region >'))}</div>
            </div>

            <div className="filters">
                <div className="filters-top">
                    <button className="remove-all-filters-button" onClick={onRemoveAllFilters}>{t('removeFilters')}</button>
                    <button className="close-filters-button" onClick={onClose}>✕</button>
                </div>
                <div className="filter">{renderMultiSelect_brands("car_brand", internalFilters.car_brand, filterOptions.car_brand, t('brand'), brandIcon)}</div>
                <div className="filter">{renderMultiSelect("car_model", internalFilters.car_model, filterOptions.car_model, t('model'), modelIcon, !(internalFilters.car_brand && internalFilters.car_brand.length > 0))}</div>
                <div className="filter">{renderOneSelect("car_gearbox", internalFilters.car_gearbox, filterOptions.car_gearbox, t('gearbox'), gearboxIcon)}</div>
                <div className="filter">{renderMultiSelect("car_fuel", internalFilters.car_fuel, filterOptions.car_fuel, t('fuel'), fuelIcon)}</div>
                <div className="filters_space"></div>
                <div className="filter">{renderRangeSelect("car_min_price", "car_max_price", { min: internalFilters.car_min_price, max: internalFilters.car_max_price }, predefinedPriceRangeOptions, t('price'), priceIcon)}</div>
                <div className="filter">{renderRangeSelect("car_min_year", "car_max_year", { min: internalFilters.car_min_year, max: internalFilters.car_max_year }, predefinedYearRangeOptions, t('year'), yearIcon)}</div>
                <div className="filter">{renderRangeSelect("car_min_km", "car_max_km", { min: internalFilters.car_min_km, max: internalFilters.car_max_km }, predefinedKmRangeOptions, t('kilometers'), kmIcon)}</div>

                <div className="filters_space"></div>
                <div className="filter">{renderOneSelect("car_seller_type", internalFilters.car_seller_type, filterOptions.car_seller_type, t('seller'), sellerIcon)}</div>
                <div className="filter">{renderOneSelect("car_doors", internalFilters.car_doors, filterOptions.car_doors, t('doors'), doorsIcon)}</div>
                <div className="filter">{renderOneSelect("car_seats", internalFilters.car_seats, filterOptions.car_seats, t('seats'), seatsIcon)}</div>
                <div className="filter">{renderMultiSelect("car_color", internalFilters.car_color, filterOptions.car_color, t('color'), colorIcon)}</div>
                
                {/* Botón "Mostrar coches" solo en versión móvil */}
                {window.innerWidth <= 768 && (
                    <div className="filters-bottom">
                        <button 
                            className="search-cars-button" 
                            onClick={() => {
                                onClose();  // Cierra el panel de filtros
                                window.scrollTo({ top: 0, behavior: 'smooth' });  // Desplaza la página hacia arriba
                            }}
                        >
                            {isLoadingCars ? (
                                <span className="spinner"></span> // Rueda de carga
                            ) : (
                                `${t('showCars', { count: totalCars.toLocaleString() })}`
                            )}
                        </button>
                    </div>  
                )}
            </div>
        </div>
    );
};

export default Filters;




const getFilterOptions = async (activeFilters, query, t, filterOptions) => {
    try {
        const activeFiltersString = JSON.stringify(activeFilters, (key, value) => {
            if (Array.isArray(value) && value.length === 1) {
                return value[0];
            }
            return value;
        });

        const baseUrl = "http://3.95.6.146:8080/get_filters/";
        const url = `${baseUrl}?active_filters=${activeFiltersString}&data=${encodeURIComponent(query)}`;

        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log('Data received from backend:', data);

        // Mezcla las opciones estáticas con los conteos dinámicos
        const options = {};

        for (const [key, staticOptionList] of Object.entries(filterOptions)) {
            if (key === "car_model" || key === "car_brand" || key === "car_location_region") {
                // Procesa car_model y car_brand dinámicamente
                const dynamicData = data[key];
                if (!dynamicData || typeof dynamicData !== 'object') {
                    console.error(`Invalid data for ${key}:`, dynamicData);
                    options[key] = [];
                } else {
                    options[key] = Object.entries(dynamicData).map(([optionKey, count]) => ({
                        value: optionKey, // El valor dinámico como clave
                        label: t(`Filters.${key}.${optionKey}`, optionKey), // Traducción con fallback
                        count: count.toLocaleString('en-US').replace(/,/g, '.'), // Formateo de conteos
                    }));
                }
            } else {
                // Normaliza las opciones estáticas con los conteos dinámicos
                const normalizedDynamicData = Object.fromEntries(
                    Object.entries(data[key] || {}).map(([dynamicKey, count]) => [
                        dynamicKey.toLowerCase(), // Normalizamos a minúsculas
                        count
                    ])
                );

                options[key] = staticOptionList.map(staticOption => {
                    const normalizedValue = staticOption.value.toLowerCase(); // Normalizamos la opción estática
                    const dynamicCount = normalizedDynamicData[normalizedValue] || 0; // Obtenemos el conteo
                    return {
                        ...staticOption,
                        count: dynamicCount.toLocaleString('en-US').replace(/,/g, '.') // Formateo
                    };
                });
            }
        }

        console.log('Generated filter options:', options);
        return options;
    } catch (error) {
        console.error('Error fetching filter options:', error);
        return {
        };
    }
};




