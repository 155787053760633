import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const RegionSelect2 = ({ options = [], placeholder, onChange, value = [], isLoading = false, icon, isDisabled }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const [pendingSelections, setPendingSelections] = useState(value);

  // Sincronizar pendingSelections con value solo cuando el componente se monta
  useEffect(() => {
    setPendingSelections(value);
  }, []); // Solo se ejecuta al montar el componente

  const handleAccept = useCallback(() => {
    onChange(pendingSelections);
    setIsOpen(false);
  }, [onChange, pendingSelections]);

  const handleClickOutside = useCallback((event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      if (isOpen) {
        if (pendingSelections.length > 0 && JSON.stringify(pendingSelections) !== JSON.stringify(value)) {
          handleAccept();
        } else {
          setIsOpen(false);
        }
      }
    }
  }, [isOpen, handleAccept, pendingSelections, value]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    if (isOpen && inputRef.current && !isMobile) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  const handleToggle = useCallback(() => {
    if (!isOpen) {
      setPendingSelections(value);
      setFilter('');
    }
    setIsOpen(!isOpen);
  }, [isOpen, value]);

  const handleSelect = useCallback((option) => {
    setPendingSelections(prev => {
      const isSelected = prev.includes(option.value);
      if (isSelected) {
        return prev.filter(val => val !== option.value);
      }
      return [...prev, option.value];
    });
  }, []);

  const handleFilterChange = useCallback((e) => {
    setFilter(e.target.value);
  }, []);

  const clearSelection = useCallback((e) => {
    e.stopPropagation();
    setPendingSelections([]);
    onChange([]);
    setIsOpen(false);
  }, [onChange]);

  const getLabelsFromValues = useCallback((values) => {
    return values.map(val => 
      options.find(option => option.value === val)?.label || val
    );
  }, [options]);

  const capitalizeLabels = (labels) => {
    return labels.map(label => label.charAt(0).toUpperCase() + label.slice(1));
  };

  const selectedLabels = capitalizeLabels(getLabelsFromValues(value));
  const pendingLabels = capitalizeLabels(getLabelsFromValues(pendingSelections));

  const filteredOptions = React.useMemo(() => {
    return options
      .map(option => ({
        ...option,
        label: option.label.charAt(0).toUpperCase() + option.label.slice(1).toLowerCase()
      }))
      .filter(option =>
        option.label.toLowerCase().includes(filter.toLowerCase())
      );
  }, [options, filter]);

  const getTruncatedText = (text) => {
    const capitalizedText = text.charAt(0).toUpperCase() + text.slice(1);
    return capitalizedText.length > 30 ? `${capitalizedText.slice(0, 27)}...` : capitalizedText;
  };

  return (
    <div 
      ref={containerRef} 
      className={`multi-select-container2 ${isOpen ? 'is-open' : ''} ${isDisabled ? 'disabled' : ''}`}
    >
      <div 
        className={`region-select-control2 ${selectedLabels.length > 0 ? 'has-selection' : ''} ${isDisabled ? 'disabled-control' : ''}`}
        onClick={!isDisabled ? handleToggle : undefined}
      >
        <div className="region-select-placeholder-container" style={{ display: 'flex', alignItems: 'center' }}>
          <div 
            className={`region-select-placeholder ${selectedLabels.length > 0 ? 'placeholder-selected' : ''}`} 
            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', flex: 1 }}
          >
            {selectedLabels.length > 0 ? getTruncatedText(selectedLabels.join(', ')) : getTruncatedText(placeholder)}
            {icon && <img src={icon} alt="" className="placeholder-icon" />}
          </div>
          {selectedLabels.length > 0 && (
            <button 
              className="location-clear-selection-button" 
              onClick={(e) => {
                e.stopPropagation(); // Evitar que el clic abra el menú
                setPendingSelections([]);
                onChange([]); // Resetear las selecciones
              }}
            >
              &times;
            </button>
          )}
        </div>
      </div>
  
      {isOpen && !isDisabled && (
        <div className="multi-select-menu2">
          <input
            ref={inputRef}
            type="text"
            className="multi-select-input"
            placeholder={t('filterPlaceholder')}
            value={filter}
            onChange={handleFilterChange}
          />
          <div className="multi-select-options">
            {filteredOptions.map(option => {
              const isSelected = pendingSelections.includes(option.value);
              return (
                <div
                  key={option.value}
                  className={`multi-select-option ${isSelected ? 'selected' : ''} ${(option.count === 0 || option.count === '0') ? 'no-results' : ''}`}
                  onClick={() => handleSelect(option)}
                >
                  {option.icon && <img src={option.icon} alt="" className="option-icon" />}
                  <span className={`multi-select-option-label ${isSelected ? 'selected' : ''}`}>
                    {option.label}
                    {!isLoading && option.count && (
                      <span className="option-count"> · {option.count}</span>
                    )}
                  </span>
                  <label className="checkbox-wrapper">
                    <input
                      type="checkbox"
                      checked={isSelected}
                      onChange={() => handleSelect(option)}
                      className="multi-select-checkbox"
                    />
                    <span className={`custom-checkbox ${isSelected ? 'checked' : ''}`}></span>
                  </label>
                </div>
              );
            })}
          </div>
          <div className="multi-select-buttons">
            <button className="clear-all-button" onClick={clearSelection}>
              {t('clearSelection')}
            </button>
            <button className="close-button" onClick={handleAccept}>
              {t('accept')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RegionSelect2;
