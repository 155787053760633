import { useState, useEffect, forwardRef } from "react";
import { useTranslation } from 'react-i18next';
import { ReactComponent as SearchIcon } from './icons/search-magnifying-glass-svgrepo-com.svg';

const SearchBar = forwardRef(({ onSearch }, ref) => {
    const [query, setQuery] = useState("");
    const { t } = useTranslation();

    // Lista de placeholders para alternar
    const placeholders = [
        t('searchPlaceholder1'),
        t('searchPlaceholder2'),
        t('searchPlaceholder3'),
    ];

    const [currentPlaceholder, setCurrentPlaceholder] = useState(placeholders[0]);
    const [fadeClass, setFadeClass] = useState("placeholder-fade-in");

    useEffect(() => {
        // Cambia el placeholder cada 5 segundos con animación
        const intervalId = setInterval(() => {
            setFadeClass("placeholder-fade-out"); // Comienza a desvanecerse

            setTimeout(() => {
                setCurrentPlaceholder(prev => {
                    const currentIndex = placeholders.indexOf(prev);
                    const nextIndex = (currentIndex + 1) % placeholders.length;
                    return placeholders[nextIndex];
                });
                setFadeClass("placeholder-fade-in"); // Aparece el nuevo placeholder
            }, 500); // Sincronizado con la duración de la transición en CSS
        }, 5000);

        return () => clearInterval(intervalId); // Limpia el intervalo al desmontar
    }, [placeholders]);

    const handleInputChange = (e) => {
        setQuery(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            onSearch(query);
        }
    };

    return (
        <div className="search-bar">
            <input
                ref={ref} // Aquí se pasa la referencia al input de búsqueda
                type="search"
                placeholder={currentPlaceholder}
                value={query}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                className={`search-input ${fadeClass}`}
            />
            <button
                type="button"
                onClick={() => onSearch(query)}
                className="search-button"
            >
                <SearchIcon />
            </button>
        </div>
    );
});

export default SearchBar;
