import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const CountrySelect2 = ({ options = [], placeholder, onChange, value = [], isLoading = false, icon, isDisabled }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const [pendingSelections, setPendingSelections] = useState(value);
  
  // Sincronizar pendingSelections con value solo cuando el componente se monta
  useEffect(() => {
    setPendingSelections(value);
  }, []); // Solo se ejecuta al montar el componente

  const handleAccept = useCallback(() => {
    onChange(pendingSelections);
    setIsOpen(false);
  }, [onChange, pendingSelections]);

  const handleClickOutside = useCallback((event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      if (isOpen) {
        // Solo acepta si se seleccionó algo nuevo
        if (pendingSelections.length > 0 && JSON.stringify(pendingSelections) !== JSON.stringify(value)) {
          handleAccept();
        } else {
          setIsOpen(false); // Simplemente cierra el menú
        }
      }
    }
  }, [isOpen, handleAccept, pendingSelections, value]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    if (isOpen && inputRef.current && !isMobile) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  const handleToggle = useCallback(() => {
    if (!isOpen) {
      setPendingSelections(value); // Resetear selecciones al abrir
      setFilter('');
    }
    setIsOpen(!isOpen);
  }, [isOpen, value]);

  const handleSelect = useCallback((option) => {
    setPendingSelections(prev => {
      const isSelected = prev.includes(option.value);
      if (isSelected) {
        return prev.filter(val => val !== option.value);
      }
      return [...prev, option.value];
    });
  }, []);

  const handleFilterChange = useCallback((e) => {
    setFilter(e.target.value);
  }, []);

  const clearSelection = useCallback((e) => {
    e.stopPropagation();
    setPendingSelections([]);
    onChange([]);
    setIsOpen(false);
  }, [onChange]);

  const getLabelsFromValues = useCallback((values) => {
    return values.map(val => 
      options.find(option => option.value === val)?.label || val
    );
  }, [options]);

  const selectedLabels = getLabelsFromValues(value);
  const pendingLabels = getLabelsFromValues(pendingSelections);
  
  const filteredOptions = React.useMemo(() => {
    return options
      .map(option => ({
        ...option,
        label: option.label.charAt(0).toUpperCase() + option.label.slice(1).toLowerCase()
      }))
      
      .filter(option =>
        option.label.toLowerCase().includes(filter.toLowerCase())
      );
  }, [options, filter]);

  return (
    <div 
      ref={containerRef} 
      className={`multi-select-container2 ${isOpen ? 'is-open' : ''} ${isDisabled ? 'disabled' : ''}`}
    >
      <div 
        className={`country-select-control2 ${selectedLabels.length > 0 ? 'has-selection' : ''} ${isDisabled ? 'disabled-control' : ''}`}
        onClick={!isDisabled ? handleToggle : undefined} // No abre el menú si está deshabilitado
      >
        <div className="multi-select-placeholder-container">
          <div className="country-select-placeholder">
            {selectedLabels.length === 1 ? (
              // Mostrar ícono y etiqueta de la opción seleccionada si hay solo una
              <>
                {options.find(option => option.value === value[0])?.icon && (
                  <img 
                    src={options.find(option => option.value === value[0]).icon} 
                    alt="" 
                    className="country-placeholder-icon" 
                  />
                )}
                <span className="selected-single-option">
                  {options.find(option => option.value === value[0])?.label.charAt(0).toUpperCase() + 
                  options.find(option => option.value === value[0])?.label.slice(1).toLowerCase()}
                </span>
              </>
            ) : selectedLabels.length > 1 ? (
              // Mostrar "Country" y número de opciones seleccionadas si hay más de una
              <span className="selected-multiple-options">
                {t('Country')}
                <span className="selection-count-circle">{selectedLabels.length}</span>
                
              </span>
            ) : (
              // Mostrar el placeholder inicial si no hay selecciones
              <>
                {icon && <img src={icon} alt="" className="country-placeholder-icon" />}
                {placeholder}
              </>
            )}
          </div>
          {/* Botón para limpiar las selecciones */}
          {selectedLabels.length > 0 && (
            <button 
              className="location-clear-selection-button" 
              onClick={(e) => {
                e.stopPropagation(); // Evitar que el clic abra el menú
                setPendingSelections([]);
                onChange([]); // Resetear las selecciones
              }}
            >
              &times;
            </button>
          )}
        </div>
      </div>



  
      {isOpen && !isDisabled && ( // Solo abre el menú si no está deshabilitado
        <div className="multi-select-menu2">
          <input
            ref={inputRef}
            type="text"
            className="multi-select-input"
            placeholder={t('filterPlaceholder')}
            value={filter}
            onChange={handleFilterChange}
          />
          <div className="multi-select-options">
            {filteredOptions.map(option => {

              const isSelected = pendingSelections.includes(option.value);
              return (
                <div
                  key={option.value}
                  className={`multi-select-option ${isSelected ? 'selected' : ''} ${(option.count === 0 || option.count === '0') ? 'no-results' : ''}`}
                  onClick={() => handleSelect(option)}
                >
                  {option.icon && <img src={option.icon} alt="" className="option-icon" />}
                  <span className={`multi-select-option-label ${isSelected ? 'selected' : ''}`}>
                    {option.label}
                    {!isLoading && option.count && (
                      <span className="option-count"> · {option.count}</span>
                    )}
                  </span>
                  <label className="checkbox-wrapper">
                    <input
                      type="checkbox"
                      checked={isSelected}
                      onChange={() => handleSelect(option)}
                      className="multi-select-checkbox"
                    />
                    <span className={`custom-checkbox ${isSelected ? 'checked' : ''}`}>
                    </span>
                  </label>
                </div>
              );
            })}
          </div>
          <div className="multi-select-buttons">
            <button className="clear-all-button" onClick={clearSelection}>
              {t('clearSelection')}
            </button>
            <button className="close-button" onClick={handleAccept}>
              {t('accept')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
  
};

export default CountrySelect2;