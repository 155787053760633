import { useEffect, useState } from "react";

const useFetch = (url, timeout = 5000, maxRetries = 5) => {
    const [data, setData] = useState({ total: 0, cars: [] });
    const [isPending, setIsPending] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!url) return;

        setIsPending(true);
        setData({ total: 0, cars: [] });
        setError(null);

        const fetchData = async (retriesLeft) => {
            const startTime = Date.now();

            try {
                const fetchPromise = fetch(url).then(response => {
                    if (!response.ok) {
                        throw new Error('Could not fetch');
                    }
                    return response.json();
                });

                const timeoutPromise = new Promise((_, reject) =>
                    setTimeout(() => reject(new Error('Request timed out')), timeout)
                );

                const result = await Promise.race([fetchPromise, timeoutPromise]);

                const elapsedTime = Date.now() - startTime;
                const remainingTime = Math.max(0, 1000 - elapsedTime);

                setTimeout(() => {
                    setData(result);
                    setIsPending(false);
                    setError(null);
                }, remainingTime);
            } catch (err) {
                if (retriesLeft > 0) {
                    console.warn(`Retrying... Attempts left: ${retriesLeft}`);
                    fetchData(retriesLeft - 1); // Retry with one less attempt
                } else {
                    const elapsedTime = Date.now() - startTime;
                    const remainingTime = Math.max(0, 500 - elapsedTime);

                    setTimeout(() => {
                        setIsPending(false);
                        setError(err.message);
                    }, remainingTime);
                }
            }
        };

        fetchData(maxRetries);
    }, [url, timeout, maxRetries]);

    return { data, isPending, error };
};

export default useFetch;
