import React from "react";

const ContactUsSection = () => {
  
  const handleContactClick = () => {
    window.location.href = "mailto:hello@spotmyauto.com?subject=Consulta";
  };

  return (
    <div className="contact-us-container">
      <div className="contact-us-content">
        <div className="contact-us-text">
          <h2>¿Quieres colaborar con nosotros?</h2>
          <p>
            Nos encantaría hablar contigo.
          </p>
        </div>
        <div className="contact-us-button">
          <button onClick={handleContactClick}>Contacta</button>
        </div>
      </div>
    </div>
  );
};

export default ContactUsSection;
