import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const RangeSelect2 = ({ options = [], placeholder, onChange, minValue = '', maxValue = '', isLoading = false, icon }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isMinOpen, setIsMinOpen] = useState(false);
  const [isMaxOpen, setIsMaxOpen] = useState(false);
  const [filterMin, setFilterMin] = useState('');
  const [filterMax, setFilterMax] = useState('');
  const containerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsOpen(false);
        setIsMinOpen(false);
        setIsMaxOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setFilterMin('');
      setFilterMax('');
      setIsMinOpen(false);
      setIsMaxOpen(false);
    }
  };

  const handleSelect = (type, option) => {
    if (type === 'min') {
      if (maxValue && parseInt(option.value) > parseInt(maxValue)) {
        alert('El precio mínimo no puede ser mayor que el precio máximo');
        return;
      }
      onChange({ min: option.value, max: maxValue });
      setIsMinOpen(false);
    } else if (type === 'max') {
      if (minValue && parseInt(option.value) < parseInt(minValue)) {
        alert('El precio máximo no puede ser menor que el precio mínimo');
        return;
      }
      onChange({ min: minValue, max: option.value });
      setIsMaxOpen(false);
    }
  };

  const clearSelection = (e) => {
    e.stopPropagation();
    onChange({ min: '', max: '' });
    setIsMinOpen(false);
    setIsMaxOpen(false);
    setIsOpen(false);
  };

  const handleAccept = () => {
    setIsOpen(false);
  };

  const handleFilterChange = (type, e) => {
    if (type === 'min') setFilterMin(e.target.value);
    if (type === 'max') setFilterMax(e.target.value);
  };

  const handleMinToggle = () => {
    setIsMinOpen((prevState) => {
      if (!prevState) setIsMaxOpen(false); // Cerrar máximo si se abre mínimo
      return !prevState;
    });
  };

  const handleMaxToggle = () => {
    setIsMaxOpen((prevState) => {
      if (!prevState) setIsMinOpen(false); // Cerrar mínimo si se abre máximo
      return !prevState;
    });
  };

  return (
    <div ref={containerRef} className="range-select-container">
      <div className="range-select-control" onClick={handleToggle}>
        <div
          className={`range-select-placeholder ${
            minValue || maxValue ? 'has-selection' : ''
          }`}
        >
          {icon && <img src={icon} className="placeholder-icon" alt="filter icon" />}
          {placeholder}
        </div>
      </div>
      {isOpen && (
        <div className="range-select-menu slide-right">
          {isLoading ? (
            <div className="loading-container">
              <span className="spinner"></span>
            </div>
          ) : (
            <>
              <div className="range-select-columns">
                <div className="range-select-column">
                  <h3>Mínimo</h3>
                  <button
                    className="range-select-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleMinToggle();
                    }}
                  >
                    {minValue ? `${minValue}` : 'Desde'}
                    {minValue && (
                      <span
                        className="clear-value-icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          onChange({ min: '', max: maxValue });
                        }}
                      >
                        ✕
                      </span>
                    )}
                  </button>
                  {isMinOpen && (
                    <div className="range-select-submenu range-select-submenu-min">
                      {options
                        .filter((option) => !maxValue || parseInt(option.value) <= parseInt(maxValue))
                        .filter((option) => option.label.toLowerCase().includes(filterMin.toLowerCase()))
                        .map((option) => (
                          <div
                            key={option.value}
                            className={`range-select-option ${minValue === option.value ? 'selected' : ''}`}
                            onClick={() => handleSelect('min', option)}
                          >
                            {option.label}
                          </div>
                        ))}
                    </div>
                  )}
                </div>
                <div className="range-select-column">
                  <h3>Máximo</h3>
                  <button
                    className="range-select-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleMaxToggle();
                    }}
                  >
                    {maxValue ? `${maxValue}` : 'Hasta'}
                    {maxValue && (
                      <span
                        className="clear-value-icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          onChange({ min: minValue, max: '' });
                        }}
                      >
                        ✕
                      </span>
                    )}
                  </button>
                  {isMaxOpen && (
                    <div className="range-select-submenu range-select-submenu-max">
                      {options
                        .filter((option) => !minValue || parseInt(option.value) >= parseInt(minValue))
                        .filter((option) => option.label.toLowerCase().includes(filterMax.toLowerCase()))
                        .map((option) => (
                          <div
                            key={option.value}
                            className={`range-select-option ${maxValue === option.value ? 'selected' : ''}`}
                            onClick={() => handleSelect('max', option)}
                          >
                            {option.label}
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="range-select-buttons">
                <button className="clear-all-button" onClick={clearSelection}>
                  {t('clearSelection')}
                </button>
                <button className="close-button" onClick={handleAccept}>
                  {t('accept')}
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default RangeSelect2;
